// import node module libraries
import React, { Fragment, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import AllChatsBottom from 'components/dashboard/chat/chatbottom/AllChatsBottom';
import ChatBottom from 'components/dashboard/chat/chatbottom/ChatBottom';

// import layouts
// import NavbarJobPages from 'layouts/marketing/navbars/NavbarJobPages';
import NavbarDefault from 'layouts/marketing/navbars/NavbarDefault';
import FooterWithLinks from 'layouts/marketing/footers/FooterWithLinks';
import NavbarMegaMenu from './navbars/mega-menu/NavbarMegaMenu';

import auth from 'functions/checkLogin';
import axios from 'axios';

const ServiceProvidersLayout = (props) => {
    // Regex para validar se o usuario está em dispositivo movel
    const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
    const hash = localStorage.getItem('hash');
    const url = process.env.REACT_APP_API_URL;
    const [login, setLogin] = useState(false);

    const getLoggedUser = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        axios
            .get(`${url}accounts/authentication/logged-user`, config)
            .then((response) => {
                // Atualiza o estado com os dados da resposta da API
                setLogin(true);
            })
            .catch((error) => {
                setLogin(false);

                return Promise.reject(error);
                // ('Erro');
            });
    };

    useEffect(() => {
        getLoggedUser();
    }, []);

    login;
    return (
        <Fragment>
            {login === true ? <NavbarDefault /> : <NavbarMegaMenu />}
            <main>
                {props.children}
                <Outlet />
            </main>
            {login == true ? (
                <div className="chat">
                    <div className="chat-element">
                        <AllChatsBottom></AllChatsBottom>
                    </div>
                    <div className="chat-element">
                        <ChatBottom />
                    </div>
                </div>
            ) : (
                ''
            )}

            <FooterWithLinks />
        </Fragment>
    );
};

export default ServiceProvidersLayout;
