import { Container, Col, Row, Card, Image, Button, Form } from 'react-bootstrap';
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroller';
import { useLocation } from 'react-router-dom';

import FeedProviders from './FeedProviders';
import VideoUpload from './components/upload/VideoUpload';
import ImageUpload from './components/upload/ImageUpload';
import Toasts from 'components/elements/toasts/Toasts';
import Posts from './components/posts/Posts';
import PostSkeleton from './components/skeleton/postSkeleton/PostSkeleton';

import './styles.css';

import UserImage from 'assets/images/avatar/user.png';
import { PeopleFill, Star } from 'react-bootstrap-icons';
import IconError from 'assets/images/icons/btn-error.png';

const Feed = () => {
	const [loggedUser, setLoggedUser] = useState(null);
	const [loading, setLoading] = useState(false);
	const [posts, setPosts] = useState([]);
	const [posting, setPosting] = useState(false);
	const [postError, setPostError] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [skip, setSkip] = useState(0);
	const [deletedPosts, setDeletedPosts] = useState([]);
	const formRef = useRef(null);

	const { register, handleSubmit, reset } = useForm();
	const location = useLocation();

	const url = process.env.REACT_APP_API_URL;
	const hash = localStorage.getItem('hash');
	const config = {
		headers: {
			Authorization: `Bearer ${hash}`
		}
	};

	useEffect(() => {
		getLoggedUser();
		fetchMoreItems();
	}, [location]);

	const getLoggedUser = () => {
		setLoading(true);
		axios
			.get(`${url}accounts/authentication/logged-user`, config)
			.then((response) => {
				setLoggedUser(response.data);
			})
			.catch((error) => {
				console.error('Erro ao buscar dados da API:', error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getPosts = () => {
		axios
			.get(`${url}social/posts?limit=5&skip=${skip}`, config)
			.then((response) => {
				setPosts((prevPosts) => {
					const newPosts = response.data.result.filter(
						(post) => !prevPosts.some((prevPost) => prevPost._id === post._id)
					);
					return [...prevPosts, ...newPosts];
				});
				if (response.data.result.length < 5) {
					setHasMore(false);
				}
				setSkip((prevSkip) => prevSkip + 5);
			})
			.catch((error) => {
				console.error('Erro ao buscar dados da API:', error);
			});
	};

	const createPost = async (data) => {
		setLoading(true);
		setPosting(true);
		const newData = {
			description: data.description ? data.description : ''
		};

		if (newData.description) {
			axios
				.post(`${url}social/posts`, newData, config)
				.then((response) => {
					const newPost = response.data;
					setPosts((prevPosts) => [newPost, ...prevPosts]);
					setPostError(false);
					reset();
				})
				.catch((error) => {
					console.error('Erro ao buscar dados da API:', error);
				})
				.finally(() => {
					setLoading(false);
					setPosting(false);
				});
		} else {
			setPostError(true);
			setTimeout(() => {
				setPostError(false);
			}, 6000);

			setLoading(false);
			setPosting(false);
		}
	};

	const fetchMoreItems = async () => {
		if (!loading && hasMore) {
			getPosts();
		}
	};

	const updatePostComments = (postId, comments) => {
		setPosts(posts.map((post) => (post._id === postId ? { ...post, comments } : post)));
	};

	const handleDeletePost = (postId) => {
		setDeletedPosts((prev) => [...prev, postId]);
		setPosts((prevPosts) => prevPosts.filter((post) => post._id !== postId));
	};

	const handleDeleteComment = (postId, commentId) => {
		setPosts(
			posts.map((post) =>
				post._id === postId
					? { ...post, comments: post.comments.filter((comment) => comment._id !== commentId) }
					: post
			)
		);
	};

	return (
		<section className="py-5 bg-light">
			<Container>
				<Row>
					<Col xl={3} xs={3} sm={0} className="mb-4 mb-xl-0 feed-profile-column">
						<Card>
							<Card.Header className="bg-primary d-flex justify-content-center position-relative p-4">
								<div className="d-flex justify-content-center mb-2 position-relative">
									<div className="avatar avatar-xl position-absolute">
										<Image
											alt="avatar"
											src={
												loggedUser?.profilePicture?.url ? loggedUser?.profilePicture.url : UserImage
											}
											className="rounded-circle feed-avatar"
										/>
									</div>
								</div>
							</Card.Header>
							<Card.Body className="mt-2">
								<div className="d-flex justify-content-center flex-column">
									<div className="d-flex justify-content-center mb-0">
										<h4 className="mb-0">
											{loggedUser?.firstName} {loggedUser?.lastName}
										</h4>
									</div>
									<div className="d-flex justify-content-center ">
										<small>{loggedUser?.portfolioTitle}</small>
									</div>
									<div className="d-flex justify-content-center align-items-center text-center">
										<small>{loggedUser?.occupationArea}</small>
									</div>
								</div>
								<hr></hr>
								<div className="d-flex justify-content-center flex-column">
									<div className="d-flex justify-content-start align-items-center mb-0">
										<Star size={16} className="text-warning" />
										<small className="user-details-small">
											{loggedUser?.userRecomendations.length} Avaliações
										</small>
									</div>
									<div className="d-flex justify-content-start align-items-center">
										<PeopleFill size={16} className="text-primary" />
										<small className="user-details-small">Seguidores</small>
									</div>
								</div>
							</Card.Body>
						</Card>
					</Col>
					<Col xl={6} xs={12} sm={12} md={12} className="mb-4 mb-xl-0">
						<Card className="mb-4">
							<Card.Body>
								<Form ref={formRef} onSubmit={handleSubmit(createPost)}>
									<div className="d-flex mb-2">
										<div className="avatar-md">
											<Image
												alt="avatar"
												src={loggedUser?.profilePicture?.url || UserImage}
												className="rounded-circle w-100"
											/>
										</div>
										<div className="flex-grow-1 ms-3">
											<Form.Control
												as="textarea"
												rows={2}
												type="text"
												id="text"
												className="post-input-new"
												placeholder="No que você está pensando?"
												{...register('description')}
											/>
										</div>
									</div>
								</Form>
								<div className="d-flex gap-2 flex-row justify-content-between mt-3">
									<div className="w-40 d-flex justify-content-between">
										<div className="d-inline-flex align-items-center">
											<ImageUpload newPostAtt={setPosts} />
										</div>
										<div className="d-inline-flex align-items-center">
											<VideoUpload newPostAtt={setPosts} />
										</div>
									</div>
									<div className="d-inline-flex align-items-center justify-content-end gap-2">
										<Button
											type="submit"
											disabled={posting}
											onClick={() => formRef.current.requestSubmit()}
										>
											{posting ? 'Enviando' : 'Publicar'}
										</Button>
									</div>
								</div>
								{postError && (
									<div className="mt-2">
										<Toasts
											icon={IconError}
											title="Informação"
											description="Adicione um comentário em sua publicação."
											color="#e50000"
											width="100%"
										/>
									</div>
								)}
							</Card.Body>
						</Card>

						<InfiniteScroll
							pageStart={0}
							loadMore={fetchMoreItems}
							hasMore={hasMore}
							loader={
								<div className="loader" key={0}>
									<PostSkeleton />
								</div>
							}
						>
							{posts &&
								posts
									.filter((item) => !deletedPosts.includes(item._id))
									.map((item) => (
										<Posts
											item={item}
											key={item._id}
											onDeletePost={handleDeletePost}
											updatePostComments={updatePostComments}
											handleDeleteComment={handleDeleteComment}
										/>
									))}
						</InfiniteScroll>
					</Col>
					<Col xl={3} xs={3} className="mb-4 mb-xl-0 feed-providers">
						<Card>
							<Card.Header>
								<h4 className="mb-0">Recomendações</h4>
							</Card.Header>
							<Card.Body>
								<FeedProviders />
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Feed;
