import {
    Container,
    Col,
    Row,
    Card,
    ListGroup,
    Image,
    Button,
    Form,
    Badge,
    Modal,
    Collapse,
    Spinner,
} from 'react-bootstrap';
import {
    Calendar4,
    Flag,
    EmojiFrown,
    CreditCard,
    CheckCircle,
    ChatDots,
    ExclamationTriangle,
    XCircle,
} from 'react-bootstrap-icons';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';

import UserImage from 'assets/images/avatar/user.png';

import 'react-calendar/dist/Calendar.css';

import auth from 'functions/checkLogin';
import RedirectLink from 'helper/redirect';

const SingleService = () => {
    auth();
    RedirectLink();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    localStorage.removeItem('redirectLink');

    const url = process.env.REACT_APP_API_URL;
    const hash = localStorage.getItem('hash');
    const config = { headers: { Authorization: `Bearer ${hash}` } };
    const value = new Date();

    const [serviceData, setServiceData] = useState();
    const [loggedUser, setLoggedUser] = useState();
    const [loading, setLoading] = useState(true);
    const [showModalContest, setShowModalContest] = useState(false);
    const [showModalCancellation, setShowModalCancellation] = useState(false);

    const getLoggedUser = async () => {
        setLoading(true);

        const config = { headers: { Authorization: `Bearer ${hash}` } };

        axios
            .get(`${url}accounts/authentication/logged-user`, config)
            .then((response) => {
                setLoggedUser(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Erro ao buscar dados da API:', error);
                setLoading(false);
            });
    };

    const getServiceData = async () => {
        setLoading(true);

        const config = { headers: { Authorization: `Bearer ${hash}` } };

        await axios
            .get(`${url}accounts/v2/service-requests/${id}`, config)
            .then((response) => {
                setServiceData(response.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const checkUser = () => {
        if (serviceData?.requestedId === loggedUser?._id) return true;
        return false;
    };

    checkUser();

    const talkWith = () => {
        if (!checkUser()) {
            dispatch({ type: 'chat/changeChat', payload: serviceData.requested });
        } else {
            dispatch({ type: 'chat/changeChat', payload: serviceData.requester });
        }
    };

    useEffect(() => {
        getLoggedUser();
        getServiceData();
        document.title = 'Prestadio - Solicitação';
    }, []);

    const handleConfirmService = async (userType) => {
        const link = 'accounts/v2/service-requests/complete-service/';

        setLoading(true);

        const config = { headers: { Authorization: `Bearer ${hash}` } };

        await axios
            .patch(`${url}${link}${id}`, '', config)
            .then(() => {
                setLoading(false);
                checkUser();
                getServiceData();
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const handleOpenContest = async () => {
        setLoading(true);

        const config = { headers: { Authorization: `Bearer ${hash}` } };

        await axios
            .patch(`${url}accounts/v2/service-requests/open-contest/${id}`, '', config)
            .then(() => {
                setLoading(false);
                checkUser();
                getServiceData();
                setShowModalContest(false);
            })
            .catch(() => {
                setLoading(false);
                setShowModalContest(false);
            });
    };

    const handleCancelService = async () => {
        setLoading(true);

        const config = { headers: { Authorization: `Bearer ${hash}` } };

        await axios
            .patch(`${url}accounts/service-requests/cancel-request/${id}`, '', config)
            .then(() => {
                setLoading(false);
                checkUser();
                getServiceData();
                setShowModalCancellation(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const handleProviderAccept = async (decision) => {
        setLoading(true);

        const dataForm = {
            providerAcceptance: decision,
            deadline: value,
        };

        await axios
            .patch(`${url}accounts/v2/service-requests/accept-budget/${id}`, dataForm, config)
            .then(() => {
                setLoading(false);
                checkUser();
                getServiceData();

                if (decision === true) setShow(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const handleProviderDecline = async (decision) => {
        setLoading(true);

        const dataForm = {
            providerAcceptance: decision,
            deadline: value,
        };

        await axios
            .patch(`${url}accounts/v2/service-requests/decline-budget//${id}`, dataForm, config)
            .then(() => {
                setLoading(false);
                checkUser();
                getServiceData();

                if (decision === true) setShowRefused(false);
            })
            .catch(() => setLoading(false));
    };

    const paymentButton = () => {
        if (
            !checkUser() &&
            serviceData?.status !== 'WAITING_PROVIDER_ACCEPT' &&
            serviceData?.status !== 'CANCELED' &&
            serviceData?.paid !== true
        ) {
            return (
                <div>
                    <ListGroup.Item className="d-flex gap-1 flex-column">
                        <div className="d-flex align-items-center flex-direction-row gap-1">
                            <CreditCard size={16} className="text-dark" />
                            <span className="text-body">Pagamento</span>
                        </div>

                        <div className="d-flex mt-2 gap">
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={loading}
                                onClick={() => generatePaymentLink(true)}
                            >
                                Realizar pagamento
                            </Button>
                        </div>
                    </ListGroup.Item>
                </div>
            );
        }
    };

    const providerDecision = () => {
        if (serviceData?.status === 'WAITING_PROVIDER_ACCEPT' && checkUser() === true) {
            return (
                <ListGroup.Item className="d-flex gap-1">
                    <Button
                        variant="success"
                        type="submit"
                        disabled={loading}
                        onClick={() => setShow(true)}
                    >
                        Aceitar solicitação
                    </Button>
                    <Button
                        variant="danger"
                        type="submit"
                        disabled={loading}
                        onClick={() => setShowRefused(true)}
                    >
                        Recusar solicitação
                    </Button>
                </ListGroup.Item>
            );
        } else {
            return (
                <ListGroup.Item>
                    <div className="d-flex align-items-center flex-direction-row gap-1 mb-3">
                        <ChatDots size={16} className="text-dark" />
                        <span className="text-body">Chat</span>
                    </div>
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={loading}
                        onClick={() => talkWith()}
                    >
                        Conversar com {checkUser() === true ? 'Solicitante' : 'Prestador'}
                    </Button>
                </ListGroup.Item>
            );
        }
    };

    const status = () => {
        if (serviceData?.status === 'WAITING_PROVIDER_ACCEPT') {
            return (
                <Badge pill bg="warning" className="me-1">
                    Aguardando aceite do Prestador
                </Badge>
            );
        } else if (serviceData?.status === 'SCHEDULING') {
            return (
                <Badge pill bg="success" className="me-1">
                    Em agendamento
                </Badge>
            );
        } else if (serviceData?.status === 'COMPLETED') {
            return (
                <Badge pill bg="success" className="me-1">
                    Finalizado
                </Badge>
            );
        } else if (serviceData?.status === 'CANCELED') {
            return (
                <Badge pill bg="dark" className="me-1">
                    Cancelado
                </Badge>
            );
        } else if (serviceData?.status === 'IN_CONTEST') {
            return (
                <Badge pill bg="dark" className="me-1">
                    Em disputa
                </Badge>
            );
        }
    };

    const cancelButton = () => {
        if (
            serviceData?.status !== 'CANCELED' &&
            serviceData?.status !== 'COMPLETED' &&
            serviceData?.status !== 'WAITING_PROVIDER_ACCEPT' &&
            serviceData?.paid !== true
        ) {
            return (
                <div>
                    <ListGroup.Item className="d-flex gap-1 flex-column">
                        <div className="d-flex align-items-center flex-direction-row gap-1">
                            <XCircle size={16} className="text-dark" />
                            <span className="text-body">Cancelamento</span>
                        </div>

                        <div className="d-flex mt-2 gap">
                            <Button
                                variant="danger"
                                type="submit"
                                disabled={loading}
                                onClick={() => setShowModalCancellation(true)}
                            >
                                Cancelar
                            </Button>
                        </div>
                    </ListGroup.Item>
                </div>
            );
        }
    };

    const confirmButton = () => {
        if (
            serviceData?.status !== 'WAITING_PROVIDER_ACCEPT' &&
            serviceData?.status !== 'CANCELED' &&
            serviceData?.status !== 'COMPLETED'
        ) {
            return (
                <ListGroup.Item>
                    <div className="d-flex align-items-center flex-direction-row gap-1">
                        {' '}
                        <CheckCircle size={16} className="text-dark" />
                        <span className="text-body">Realização do Serviço</span>
                    </div>

                    <div className="d-flex mt-2 gap">
                        <Button
                            variant="success"
                            type="submit"
                            disabled={loading}
                            onClick={() => handleConfirmService()}
                        >
                            {loading && (
                                <span
                                    className="spinner-border spinner-border-sm me-2"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            )}
                            Confirmar
                        </Button>
                    </div>
                </ListGroup.Item>
            );
        }
    };

    const generatePaymentLink = async () => {
        setLoading(true);

        const dataForm = {
            value: serviceData.total,
            productDescription: 'Prestadio',
            userServiceRequestId: serviceData._id,
        };

        await axios
            .post(`${url}mercadopago/generate-payment-link`, dataForm, config)
            .then((response) => {
                window.location.href = response.data.paymentLink;
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const paymentStatus = () => {
        if (serviceData?.paid === true) {
            return (
                <Badge pill bg="success" className="me-1">
                    Pagamento realizado
                </Badge>
            );
        } else {
            return (
                <Badge pill bg="danger" className="me-1">
                    Pagamento não realizado
                </Badge>
            );
        }
    };

    const goToChat = () => {
        navigate(`/chat`);
    };

    const handleSetRating = () => {
        navigate(`/prestadores/formulario-avaliacao/${id}`);
    };

    const handleSetComplain = () => {
        navigate(`/prestadores/formulario-reclamacao/${id}`);
    };

    const [show, setShow] = useState(false);
    const [showRefused, setShowRefused] = useState(false);
    const handleClose = () => {
        setShow(false);
        setShowRefused(false);
    };
    const handleShow = () => setShow(true);
    const [open, setOpen] = useState(false);
    const [fileMessage, setFileMessage] = useState();
    const [fileError, setFileError] = useState();
    const [loadingFiles, setLoadingFiles] = useState();

    async function setFiles(e) {
        setLoadingFiles(true);
        const { files } = e.target;
        const newFormData = new FormData();

        let podeEnviar = true;

        if (e.target.files.length > 12 || loggedUser?.portfolioPictures.length >= 12) {
            setFileMessage('Você pode enviar até 12 fotos no portfólio');
            setFileError(true);
            podeEnviar = false;
            setLoadingFiles(false);
            e.target.value = null;
        }

        for (let i = 0; i < e.target.files.length && e.target.files.length <= 12; i++) {
            newFormData.append('files', files[i]);
        }

        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        if (podeEnviar == true) {
            await axios
                .post(`${url}accounts/service-requests/${serviceData._id}/files`, files, config)

                .then((response) => {
                    setLoading(false);
                    if (e.target.files.length <= 1) {
                        setFileMessage('Arquivo enviado com sucesso!');
                        setLoadingFiles(false);
                        e.target.value = null;
                    } else {
                        setFileMessage('Arquivos enviados com sucesso!');
                        setLoadingFiles(false);
                        e.target.value = null;
                    }

                    getServiceData();
                    setLoadingFiles(false);
                })

                .catch((error) => {
                    setLoadingFiles(false);
                    setFileMessage('Erro ao enviar imagem, tente novamente mais tarde!');
                    e.target.value = null;
                });
        }
    }

    const [removingFile, setRemovingFile] = useState(false);
    const deleteFile = async (item) => {
        setRemovingFile(true);
        await axios
            .delete(`${url}accounts/service-requests/${serviceData._id}/files/${item}`, config)

            .then((response) => {
                setRemovingFile(false);
                getServiceData();
            })

            .catch((error) => {
                setRemovingFile(false);
            });
    };

    return (
        <section className="py-8 bg-light">
            {/* <div className="modal">
                <Toasts
                    color={'#ff0000'}
                    description={'Aconteceu um erro, tente novamente mais tarde.'}
                    title={'Erro'}
                    width="100%"
                    icon={IconError}
                />
            </div> */}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Aceite de Solicitação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Confirme para aceitar a solicitação de serviço de{' '}
                        {serviceData?.requester?.firstName}.
                    </p>{' '}
                    {/* <div className="d-flex justify-content-center flex-column align-items-center">
                        <Calendar
                            onChange={onChange}
                            value={value}
                            minDate={new Date()}
                            className="calendar"
                        />
                        <hr></hr>
                        <strong>Data selecionada: {dataFormatada}</strong>
                    </div> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={() => handleProviderAccept(true)}>
                        Aceitar solicitação
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showRefused} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Recusar Solicitação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Confirme para recusar a solicitação de serviço de{' '}
                        {serviceData?.requester?.firstName}.
                    </p>{' '}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={() => handleProviderDecline(false)}>
                        Recusar solicitação
                    </Button>
                </Modal.Footer>
            </Modal>
            <Container>
                <Row>
                    <Col xl={8} xs={12} className="mb-4 mb-xl-0">
                        <div className="mb-4">
                            <Card>
                                <Card.Header className="card-header">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h4 className="mb-0">Solicitação de Serviço</h4>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {/*<p>{serviceData?.requestDescription}</p>*/}
                                    <ListGroup variant="flush">
                                        <ListGroup.Item className="px-0">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <Calendar4 size={16} className="text-primary" />
                                                    <div className="ms-2">
                                                        <h5 className="mb-0 text-body">
                                                            Data de Solicitação
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="text-dark mb-0 fw-semi-bold">
                                                        {moment
                                                            .utc(serviceData?.createdAt)
                                                            .format('DD/MM/YYYY')}
                                                    </p>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                        {/* <ListGroup.Item className="px-0">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <Calendar4 size={16} className="text-primary" />
                                                        <div className="ms-2">
                                                            <h5 className="mb-0 text-body">Prazo</h5>
                                                         </div>
                                                    </div>
                                                    <div>
                                                        <p className="text-dark mb-0 fw-semi-bold">
                                                            {serviceData?.deadline
                                                            ? moment.utc(serviceData?.deadline).format('DD/MM/YYYY')
                                                            : ''}
                                                        </p>
                                                    </div>
                                                </div>
                                            </ListGroup.Item> */}
                                        <ListGroup.Item className="px-0">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <Flag size={16} className="text-primary" />
                                                    <div className="ms-2">
                                                        <h5 className="mb-0 text-body">
                                                            Status do Serviço
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="text-dark mb-0 fw-semi-bold">
                                                        {status()}
                                                    </p>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                        {/* <ListGroup.Item className="px-0">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <Flag size={16} className="text-primary" />
                                                    <div className="ms-2">
                                                        <h5 className="mb-0 text-body">
                                                            Status do Pagamento
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="text-dark mb-0 fw-semi-bold">
                                                        {paymentStatus()}
                                                    </p>
                                                </div>
                                            </div>
                                        </ListGroup.Item> */}
                                        {serviceData?.complain ? (
                                            <ListGroup.Item className="px-0">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <EmojiFrown
                                                            size={16}
                                                            className="text-primary"
                                                        />
                                                        <div className="ms-2">
                                                            <h5 className="mb-0 text-body">
                                                                Reclamação aberta
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p className="text-dark mb-0 fw-semi-bold">
                                                            <Badge
                                                                pill
                                                                bg="danger"
                                                                className="me-1"
                                                            >
                                                                SIM
                                                            </Badge>
                                                        </p>
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                        ) : (
                                            ''
                                        )}
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="mb-4">
                            <Card>
                                <Card.Header className="d-flex align-items-center justify-content-between">
                                    <h4>Arquivos: {serviceData?.files?.length}</h4>{' '}
                                    <Button
                                        onClick={() => setOpen(!open)}
                                        aria-controls="example-collapse-text"
                                        aria-expanded={open}
                                        size="xs"
                                    >
                                        Visualizar
                                    </Button>
                                </Card.Header>
                                {open === true ? (
                                    <Card.Body>
                                        <Collapse in={open} className="mb-4">
                                            <div id="example-collapse-text" className="pt-3">
                                                <ListGroup className="mb-2">
                                                    {serviceData?.files &&
                                                        serviceData?.files.map((file, index) => {
                                                            return (
                                                                <ListGroup.Item className="d-flex align-items-center justify-content-between">
                                                                    <div className="d-flex align-items-center flex-row gap-3">
                                                                        <div>
                                                                            <Link to={file?.url}>
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width="16"
                                                                                    height="16"
                                                                                    fill="currentColor"
                                                                                    class="bi bi-download"
                                                                                    viewBox="0 0 16 16"
                                                                                >
                                                                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                                                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                                                                                </svg>
                                                                            </Link>
                                                                        </div>
                                                                        <Link to={file?.url}>
                                                                            {file?.key}
                                                                        </Link>
                                                                    </div>
                                                                    <div className="d-flex align-items-center flex-row gap-3">
                                                                        <div
                                                                            className="cursor-pointer"
                                                                            onClick={() =>
                                                                                deleteFile(
                                                                                    file?._id
                                                                                )
                                                                            }
                                                                        >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                class="bi bi-trash3"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </ListGroup.Item>
                                                            );
                                                        })}
                                                </ListGroup>
                                                {loadingFiles === true || removingFile === true ? (
                                                    <Spinner
                                                        animation="border"
                                                        role="status"
                                                        className="m-5"
                                                        size="sm"
                                                    >
                                                        <span className="visually-hidden">
                                                            Loading...
                                                        </span>
                                                    </Spinner>
                                                ) : null}
                                            </div>
                                        </Collapse>
                                        <div className="mb-2">
                                            <Form.Control
                                                type="file"
                                                multiple
                                                onChange={(e) => setFiles(e)}
                                            />
                                        </div>
                                    </Card.Body>
                                ) : null}
                            </Card>
                        </div>
                        <div className="mb-4">
                            <Card>
                                <Card.Header className="card-header">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h4 className="mb-0">Serviços solicitados</h4>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <ListGroup variant="flush">
                                        {serviceData?.requestedServices &&
                                        serviceData?.requestedServices?.length > 0
                                            ? serviceData?.requestedServices.map((item, index) => (
                                                  <ListGroup.Item className="px-0" key={index}>
                                                      <div className="d-flex justify-content-between align-items-center">
                                                          <div className="d-flex align-items-center">
                                                              <div className="ms-2">
                                                                  <h5 className="mb-0 text-body">
                                                                      {item.title}
                                                                  </h5>
                                                              </div>
                                                          </div>
                                                          <div>
                                                              <p className="text-dark mb-0 fw-bold text-primary">
                                                                  {item?.value
                                                                      ? item?.value.toLocaleString(
                                                                            'pt-BR',
                                                                            {
                                                                                style: 'currency',
                                                                                currency: 'BRL',
                                                                            }
                                                                        )
                                                                      : ''}
                                                                  {/* Substitua isso pelo valor real que você deseja exibir */}
                                                              </p>
                                                          </div>
                                                      </div>
                                                  </ListGroup.Item>
                                              ))
                                            : ''}

                                        <ListGroup.Item className="px-0">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <div className="ms-2">
                                                        <h5 className="mb-0 text-body">
                                                            <strong>Valor Total</strong>
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="text-dark mb-0 fw-semi-bold">
                                                        {serviceData?.total
                                                            ? serviceData?.total.toLocaleString(
                                                                  'pt-BR',
                                                                  {
                                                                      style: 'currency',
                                                                      currency: 'BRL',
                                                                  }
                                                              )
                                                            : ''}
                                                    </p>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                        </div>

                        <div className="d-flex flex-direction-row gap-2 mb-4">
                            <Row>
                                <Col md={6} lg={6} sm={12}>
                                    <Card className="mb-2 mr-2 user-card">
                                        <Card.Body>
                                            <div className="d-flex align-items-center">
                                                <div className="avatar avatar-xl">
                                                    <Image
                                                        src={
                                                            serviceData?.requester?.profilePicture
                                                                ? serviceData.requester
                                                                      .profilePicture.url
                                                                : UserImage
                                                        }
                                                        alt=""
                                                        className="rounded-3 avatar-xl image-profile"
                                                    />
                                                </div>

                                                <div className="ms-3  flex-direction-column cursor-pointer">
                                                    <h4 className="mb-0">
                                                        <Link
                                                            to={`/prestadores/listagem/${serviceData?.requester?._id}`}
                                                            className="text-inherit"
                                                        >
                                                            Solicitante
                                                        </Link>
                                                    </h4>
                                                    <div>
                                                        <small className="mb-0 text-muted">
                                                            {serviceData?.requester?.firstName}{' '}
                                                            {serviceData?.requester?.lastName}
                                                        </small>
                                                    </div>
                                                    <small className="mb-0 text-muted">
                                                        {serviceData?.requester?.city}
                                                    </small>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={6} lg={6} sm={12}>
                                    <Card className="mb-2 user-card">
                                        <Card.Body>
                                            <div className="d-flex align-items-center">
                                                <div className="avatar avatar-xl">
                                                    <Image
                                                        src={
                                                            serviceData?.requested?.profilePicture
                                                                ? serviceData.requested
                                                                      .profilePicture.url
                                                                : UserImage
                                                        }
                                                        alt=""
                                                        className="rounded-3 avatar-xl image-profile"
                                                    />
                                                </div>

                                                <div className="ms-3  flex-direction-column">
                                                    <h4 className="mb-0">
                                                        <Link
                                                            to={`/prestadores/listagem/${serviceData?.requested?._id}`}
                                                            className="text-inherit"
                                                        >
                                                            Prestador
                                                        </Link>
                                                    </h4>
                                                    <div>
                                                        <small className="mb-0 text-muted">
                                                            {serviceData?.requested?.firstName}{' '}
                                                            {serviceData?.requested?.lastName}
                                                        </small>
                                                    </div>
                                                    <small className="mb-0 text-muted">
                                                        {serviceData?.requested?.city}
                                                    </small>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xl={4} xs={12} className="mb-4 mb-xl-0">
                        <Card className="mt-4 mt-lg-0 mb-4">
                            <Card.Header className="d-lg-flex">
                                <h4 className="mb-0">Ações</h4>
                            </Card.Header>
                            <Card.Body className="p-0">
                                {/*  List group  */}
                                <ListGroup variant="flush">
                                    {providerDecision()}
                                    {confirmButton()}
                                    {paymentButton()}
                                    {/* 
                                    {checkUser() === true
                                        ? ''
                                        : serviceData?.status !== 'CANCELED' && (
                                              <ListGroup.Item>
                                                  <div className="d-flex align-items-center flex-direction-row gap-1">
                                                      {' '}
                                                      <Clipboard2Check
                                                          size={16}
                                                          className="text-dark"
                                                      />
                                                      <span className="text-body">Avaliação</span>
                                                  </div>
                                                  <div className="d-flex mt-2">
                                                      <Button
                                                          variant="light"
                                                          type="submit"
                                                          onClick={handleSetRating}
                                                      >
                                                          Enviar avaliação
                                                      </Button>
                                                  </div>
                                              </ListGroup.Item>
                                          )} */}
                                    {serviceData?.status !== 'IN_CONTEST' ? (
                                        <ListGroup.Item>
                                            <div className="d-flex align-items-center flex-direction-row gap-1">
                                                {' '}
                                                <ExclamationTriangle
                                                    size={16}
                                                    className="text-dark"
                                                />
                                                <span className="text-body">Disputa</span>
                                            </div>
                                            <div className="d-flex mt-2">
                                                <Button
                                                    variant="dark"
                                                    type="submit"
                                                    onClick={() => setShowModalContest(true)}
                                                >
                                                    Abrir disputa
                                                </Button>
                                            </div>
                                        </ListGroup.Item>
                                    ) : null}

                                    {cancelButton()}
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    </Col>
                    {serviceData?.complain ? (
                        <Col>
                            <Card>
                                <Card.Header>
                                    <h5>Reclamação aberta</h5>
                                </Card.Header>
                                <Card.Body>{serviceData?.complain}</Card.Body>
                            </Card>
                        </Col>
                    ) : (
                        ''
                    )}
                </Row>
                <Modal show={showModalContest} onHide={() => setShowModalContest(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Atenção</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Ao clicar no botão "Abrir Disputa", você estará iniciando um processo formal
                        de resolução de problemas com o usuário{' '}
                        <strong>
                            {serviceData?.requested?.firstName} {serviceData?.requested?.lastName}
                        </strong>
                        , com quem você está atualmente envolvido em um serviço. Este recurso foi
                        desenvolvido para resolver possíveis divergências de forma justa e eficaz.
                        <br />
                        <br />A partir do momento em que a disputa é aberta, o pagamento relacionado
                        ao serviço em questão será temporariamente bloqueado. Isso significa que
                        nenhum dos envolvidos terá acesso aos fundos até que uma decisão seja
                        alcançada. <br />
                        <br />
                        Durante esse período, nossa equipe estará monitorando de perto o andamento
                        da disputa. Ressaltamos que a Prestadio poderá intervir no processo, se
                        necessário, para garantir uma avaliação imparcial e justa. Nosso objetivo é
                        assegurar que ambas as partes envolvidas sejam ouvidas e que uma resolução
                        equitativa seja alcançada.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModalContest(false)}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={handleOpenContest}>
                            Quero prosseguir
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showModalCancellation} onHide={() => setShowModalCancellation(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Cancelamento de Solicitação</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Ao clicar no botão de cancelamento, sua solicitação de serviço será
                        imediatamente cancelada. <br />
                        <br />
                        Deseja continuar?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="light" onClick={() => setShowModalCancellation(false)}>
                            Não
                        </Button>
                        <Button variant="primary" onClick={handleCancelService}>
                            Sim
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </section>
    );
};

export default SingleService;
