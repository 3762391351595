import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Col, Row, Table, Card, Form, Image } from 'react-bootstrap';
import { useTable, useFilters, useGlobalFilter, usePagination } from 'react-table';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';

import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';
import { FormSelect } from 'components/elements/form-select/FormSelect';

import { getStatusColor } from 'helper/utils';

import UserImage from 'assets/images/avatar/user.png';

const RequestsListTable = () => {
    const navigate = useNavigate();

    const filterOptions = [
        { value: 'WAITING_PROVIDER_ACCEPT', label: 'Aguardando aceite' },
        { value: 'SCHEDULING', label: 'Em andamento' },
        { value: 'requested', label: 'Contratados' },
        { value: 'requester', label: 'Prestados' },
    ];

    const [filter, setFilter] = useState('WAITING_PROVIDER_ACCEPT');

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };
    const url = process.env.REACT_APP_API_URL;
    const hash = localStorage.getItem('hash');

    const [requests, setRequests] = useState([]);

    const getRequests = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        const filters = {
            SCHEDULING: 'filters[status]=SCHEDULING',
            WAITING_PROVIDER_ACCEPT: 'filters[status]=WAITING_PROVIDER_ACCEPT ',
            requested: 'filters[type]=requested',
            requester: 'filters[type]=requester',
        };

        try {
            const response = await axios.get(
                `${url}accounts/v2/service-requests?${filters[filter] || ''}`,
                config
            );
            setRequests(response.data.result);
        } catch (error) {}
    };

    useEffect(() => {
        getRequests();
    }, [filter]);

    const viewService = (id) => {
        const url = `/solicitacoes/${id}`;
        navigate(url);
    };

    const columns = useMemo(
        () => [
            { accessor: 'id', Header: 'ID', show: false },
            {
                accessor: '_id',
                Header: 'ID',
                Cell: ({ value }) => {
                    return (
                        <div
                            className="d-flex align-items-center"
                            onClick={() => viewService(value)}
                        >
                            <div className="icon-shape icon-lg rounded-3 border p-1">
                                <i className={`fe fe-clipboard fs-3 text-muted`}></i>
                            </div>
                            <div className="ms-3">
                                <h4 className="mb-0">
                                    <Link to="#" className="text-inherit">
                                        {value.slice(-8)}
                                    </Link>
                                </h4>
                            </div>
                        </div>
                    );
                },
            },
            {
                accessor: 'createdAt',
                Header: 'Data da Solicitação',
                Cell: ({ value }) => {
                    return moment(value).format('DD/MM/yyyy hh:mm:ss');
                },
            },

            // {
            //     accessor: 'deadline',
            //     Header: 'Data Prazo',
            //     Cell: ({ value }) => {
            //         return value ? moment(value).format('DD/MM/yyyy') : '';
            //     },
            // },

            {
                accessor: 'requester',
                Header: 'Solicitante',
                Cell: ({ value }) => {
                    return (
                        <div className="d-flex align-items-center">
                            <div className="me-2 align-items-center">
                                <div className="avatar avatar-md user-image">
                                    <Image
                                        alt="avatar"
                                        src={
                                            value?.profilePicture
                                                ? value?.profilePicture.url
                                                : UserImage
                                        }
                                        className="rounded-circle"
                                    />
                                </div>

                                <span className="">
                                    {value?.firstName} {value?.lastName}
                                </span>
                            </div>
                        </div>
                    );
                },
            },
            {
                accessor: 'total',
                Header: 'Valor',
                Cell: ({ value }) => {
                    return (
                        <div className="d-flex align-items-center">
                            <div className="me-2 align-items-center">
                                <span className="">
                                    {value
                                        ? value.toLocaleString('pt-BR', {
                                              style: 'currency',
                                              currency: 'BRL',
                                          })
                                        : ''}
                                </span>
                            </div>
                        </div>
                    );
                },
            },

            {
                accessor: 'status',
                Header: 'Status',
                Cell: ({ value }) => {
                    return (
                        <span
                            className={`badge bg-light-${getStatusColor(
                                value
                            )} text-dark-${getStatusColor(value)}`}
                        >
                            {value === 'SCHEDULING'
                                ? 'Em agendamento'
                                : value === 'COMPLETED'
                                ? 'Finalizado'
                                : value === 'WAITING_PROVIDER_ACCEPT'
                                ? 'Aguardando Aceite do Prestador'
                                : value === 'CANCELED'
                                ? 'Cancelado'
                                : value === 'WAITING_REQUESTER_ACCEPT'
                                ? 'Aguardando aceite de orçamento'
                                : 'Nenhuma das condições anteriores'}
                        </span>
                    );
                },
            },
        ],
        []
    );

    const data = useMemo(() => requests);

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        nextPage,
        previousPage,
        state,
        gotoPage,
        pageCount,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageSize: 10,
                hiddenColumns: columns.map((column) => {
                    if (column.show === false) return column.accessor || column.id;
                    else return false;
                }),
            },
        },
        useFilters,
        useGlobalFilter,
        usePagination
    );

    const { pageIndex, globalFilter } = state;

    return (
        <Fragment>
            <Row className="justify-content-md-between mb-4 mb-xl-0 ">
                <Col xl={2} lg={4} md={6} xs={12}>
                    <div className="mb-2 mb-lg-4">
                        <GlobalFilter
                            filter={globalFilter}
                            setFilter={setGlobalFilter}
                            placeholder="Procurar"
                        />
                    </div>
                </Col>
                <Row className="mb-4 mb-xl-0" style={{ width: '300px' }}>
                    <Col>
                        <Form.Control
                            as={FormSelect}
                            placeholder={
                                filterOptions.filter((item) => item.value === filter).label
                            }
                            options={filterOptions}
                            value={filter}
                            onChange={handleFilterChange}
                        />
                    </Col>
                </Row>
            </Row>

            <Row>
                <Col lg={12} md={12} sm={12}>
                    <Card>
                        <Card.Body className="p-0">
                            <div className="table-responsive border-0 overflow-y-hidden">
                                <Table
                                    hover
                                    {...getTableProps()}
                                    className="text-nowrap table-centered"
                                >
                                    <thead>
                                        {headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map((column) => (
                                                    <th {...column.getHeaderProps()}>
                                                        {column.render('Header')}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {page.map((row) => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map((cell) => {
                                                        return (
                                                            <td
                                                                {...cell.getCellProps()}
                                                                className="align-middle cursor-pointer"
                                                                onClick={() =>
                                                                    viewService(row.original._id)
                                                                }
                                                            >
                                                                {cell.render('Cell')}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                        <tr>
                                            <td className="align-middle " colSpan="7"></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Card.Body>
                    </Card>

                    <div className="mt-4">
                        <Pagination
                            previousPage={previousPage}
                            pageCount={pageCount}
                            pageIndex={pageIndex}
                            gotoPage={gotoPage}
                            nextPage={nextPage}
                        />
                    </div>
                </Col>
            </Row>
        </Fragment>
    );
};

export default RequestsListTable;
