import { BrowserRouter as Router } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import AllRoutes from 'layouts/AllRoutes';

import { Provider } from 'react-redux';
import store from 'redux/store';

import 'simplebar/dist/simplebar.min.css';
import 'tippy.js/animations/scale.css';

import ScrollToTop from 'layouts/dashboard/ScrollToTop';
import AuthLayout from 'layouts/dashboard/AuthLayout';

// import components
import SocketIOComponent from 'components/notifications/MenuNotifications';
import SeeApp from 'components/elements/see-app/SeeApp';
// import AllChatsBottom from 'components/dashboard/chat/chatbottom/AllChatsBottom';
// import ChatBottom from 'components/dashboard/chat/chatbottom/ChatBottom';

function App() {
    return (
        <Provider store={store}>
            <Router>
                <ScrollToTop />
                <AllRoutes />
                <SocketIOComponent />
                {/* Falta apenas o link para direcionar para o app */}
                {/* <SeeApp /> */}
            </Router>
        </Provider>
    );
}

export default App;
