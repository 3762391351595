import { useMemo, useCallback, useState, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidV4 } from 'uuid';
import { doc, updateDoc, arrayUnion, Timestamp } from 'firebase/firestore';
import { db, storage } from './init-firebase';
import axios from 'axios';

export const useMessage = (chatId, user, destinationUserId) => {
  const url = process.env.REACT_APP_API_URL;
  const hash = localStorage.getItem('hash');
  const [currentChatId, setCurrentChatId] = useState(chatId);

  useEffect(() => {
    setCurrentChatId(chatId);
  }, [chatId]);

  const api = axios.create({
    baseURL: url,
  });

  // remove \" from destinationUserId on start and end
  const fixedDestinationUserId = destinationUserId?.replace(/\"/g, '');

  const chatDoc = useMemo(() => {
    return currentChatId ? doc(db, `chat/${currentChatId}`) : null;
  }, [currentChatId]);

  const sendMessage = useCallback(
    async (message) => {
      const config = {
        headers: {
          Authorization: `Bearer ${hash}`,
        },
      };

      const { data } = await api.post(`/messages/v2`, {
        destinationUserId: fixedDestinationUserId,
        message: message,
        authorFirstName: user.firstName,
        authorLastName: user.lastName,
      }, config)

      setCurrentChatId(data.chatId);

    },
    [currentChatId],
  );

  // const sendImage = useCallback(
  //   async (file) => {
  //     if (file) {
  //       const imageName = file.name;
  //       const chatImageRef = ref(storage, chatId);
  //       const imageRef = ref(chatImageRef, imageName);
  //       await uploadBytes(imageRef, file);
  //       const imageUrl = await getDownloadURL(imageRef);
  //       if (chatDoc) {
  //         updateDoc(chatDoc, {
  //           messages: arrayUnion({
  //             message: '',
  //             image: imageUrl,
  //             file: '',
  //             file_name: '',
  //             id: uuidV4(),
  //             status: 'sent',
  //             created_at: Timestamp.now(),
  //             author: user.name,
  //             author_id: user.id,
  //           }),
  //         });
  //       }
  //     }
  //   },
  //   [chatId],
  // );

  const sendFile = useCallback(
    async (file) => {
      if (file) {
        const config = {
          headers: {
            Authorization: `Bearer ${hash}`,
          },
        };

        const formData = new FormData();
        formData.append('file', file);
  
        const { data } = await api.post(`/messages/v2/file/${fixedDestinationUserId}`, formData, config)
  
        setCurrentChatId(data.chatId);
      }
    },
    [currentChatId],
  );

  return {
    sendMessage,
    chatDoc,
    sendFile,
  };
};
