import { styled, keyframes } from 'styled-components';

const handleOpenAndClose = keyframes`
  from {
    bottom: -350px;
  }
  to {
    bottom: 0px;
  }
`;

export const Container = styled.div`
	display: none;

	@media screen and (max-width: 976px) {
		display: block;
		position: fixed;
		background-color: #011c25;
		right: 0;
		left: 0;
		z-index: 19;
		border-top-right-radius: 15px;
		border-top-left-radius: 15px;
		animation: ${handleOpenAndClose} 0.5s;
		bottom: ${(props) => props.close};
		transition: 0.5s;
	}
`;

export const Wrapper = styled.div`
	padding: 20px;
	padding-bottom: 30px;
	padding-top: 30px;
`;

export const ImageWrapperContent = styled.div`
	display: flex;
	width: fit-content;
	align-items: center;
	gap: 10px;
	padding: 0;
	margin: 0;
`;

export const Image = styled.img`
	width: 40px;
	padding: 0;
`;

export const Button = styled.a`
	max-width: 130px;
	padding: 15px;
	text-align: center;
	border-radius: 30px;
	border: none;
	color: ${(props) => props.color};
	background-color: ${(props) => props.background};
`;
