/* eslint-disable import/no-unused-modules */
// import node module libraries
import {
    Row,
    Col,
    Container,
    Button,
    Image,
    Collapse,
    Form,
    Alert,
    Badge,
    Card,
    ListGroup,
    Carousel,
    Modal,
} from 'react-bootstrap';
import moment from 'moment';

import { useParams, useNavigate } from 'react-router-dom';

import _ from 'lodash';
import { Link } from 'react-router-dom';

import { useEffect, useState } from 'react';
import axios from 'axios';

import { useForm } from 'react-hook-form';
import UserImage from 'assets/images/avatar/user.png';
import { useDispatch } from 'react-redux';

import ProfileBackground from 'assets/images/background/profile-bg.jpg';
import { BalloonHeartFill } from 'react-bootstrap-icons';
import Payment from 'components/Payment/Payment';

const ServiceProviderProfile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const url = process.env.REACT_APP_API_URL;
    const hash = localStorage.getItem('hash');
    const me = localStorage.getItem('userData');

    const [providerData, setProviderData] = useState();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [submitSuccess, setSubmitSucces] = useState();
    const [successMessage, setSuccessMessage] = useState(false);
    const [following, setFollowing] = useState();
    const [isFollowing, setIsFollowing] = useState(false);
    const [showPayment, setShowPayment] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [iAmTheProvider, setIAmTheProvider] = useState(false);
    const [requestServices, setRequestServices] = useState([]);
    const [show, setShow] = useState(false);
    const [requestedServicesIds, setRequestedServicesIds] = useState([]);
    const [recommendations, setRecommendations] = useState();
    const [login, setLogin] = useState(false);
    const [loggedUser, setLoggedUser] = useState();
    const [selectedImage, setSelectedImage] = useState();
    const { handleSubmit } = useForm();

    const getLoggedUser = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        axios
            .get(`${url}accounts/authentication/logged-user`, config)
            .then((response) => {
                // Atualiza o estado com os dados da resposta da API
                setLogin(true);
                setLoggedUser(response.data);
            })
            .catch((error) => {
                setLogin(false);
            });
    };

    const getProviderData = async () => {
        setLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        axios
            .get(`${url}accounts/users/${id}`, config)
            .then((response) => {
                // Atualiza o estado com os dados da resposta da API
                setProviderData(response.data);

                setLoading(false);

                const meData = JSON.parse(me);

                if (response.data._id === meData.id) {
                    setIAmTheProvider(true);
                } else {
                    setIAmTheProvider(false);
                }
            })
            .catch((error) => {
                console.error('Erro ao buscar dados da API:', error);
                setLoading(false);
            });
    };

    const handleTextarea = () => {
        return providerData?.portfolioAbout
            ? providerData.portfolioAbout
                  .split('\n')
                  .map((paragraph, index) => <p key={index}>{paragraph}</p>)
            : 'Não informado';
    };

    const sendFirstMessage = async () => {
        setIsVisible(toggleChat);
        setLoading(true);
        dispatch({ type: 'chat/changeChat', payload: providerData });
        localStorage.setItem('CurrentChat', JSON.stringify(providerData._id));
    };

    async function getRecommendations() {
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        await axios
            .get(`${url}accounts/recommendations/user/${id}`, config)

            .then((response) => {
                setRecommendations(response.data.result);
            })

            .catch((error) => {});
    }

    async function listFollowing() {
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        await axios
            .get(`${url}accounts/follows/follows`, config)

            .then((response) => {
                setFollowing(response.data.result);
                const usersFollowing = response.data.result?.map(
                    (following) => following.followedId
                );

                if (usersFollowing.includes(id)) {
                    setIsFollowing(true);
                } else {
                    setIsFollowing(false);
                }
            })

            .catch((error) => {});
    }

    useEffect(() => {
        getLoggedUser();
        getProviderData();
        getRecommendations();
        listFollowing();
    }, [id]);

    const handleHover = () => {
        setHovered(!hovered);
    };

    async function onSubmit(data) {
        setLoading(true);

        const dataForm = { requestedServiceIds: requestedServicesIds };
        const config = { headers: { Authorization: `Bearer ${hash}` } };

        await axios
            .post(`${url}accounts/service-requests/request/${providerData._id}`, dataForm, config)
            .then(() => {
                setSubmitSucces(true);
                setSuccessMessage('Solicitação enviada com sucesso!');
                setLoading(false);
                setShowPayment(true);
                setOpen(false);
            })
            .catch(() => {
                if (error.response.data.errorCode === 141) {
                    setErrorMessage('Você não pode solicitar um serviço a você mesmo.');
                } else {
                    setErrorMessage(
                        'Não foi possível enviar a solicitação, tente novamente mais tarde.'
                    );
                }
                setSubmitSucces(false);
                setLoading(false);
            });
    }

    async function followUser() {
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        await axios
            .post(`${url}accounts/follows/follow/${id}`, '', config)

            .then((response) => {
                setIsFollowing(true);
                getProviderData();
            })

            .catch((error) => {});
    }

    async function unfollowUser() {
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        await axios
            .delete(`${url}accounts/follows/unfollow/${id}`, config)

            .then((response) => {
                setIsFollowing(false);
            })

            .catch((error) => {});
    }

    const handleServiceSelect = (label) => {
        // Verifica se o objeto já está na array com base na propriedade serviceTitle
        const estaNaLista = requestServices.find((item) => item.id === label.id);

        if (estaNaLista) {
            // Remove o objeto se estiver na array
            const newArray = requestServices.filter((item) => item.id !== label.id);
            setRequestServices(newArray);

            const newArrayIds = requestedServicesIds.filter((item) => item !== label.id);

            setRequestedServicesIds(newArrayIds);
        } else {
            // Adiciona o objeto se não estiver na array
            const newArray = [...requestServices, label];
            setRequestServices(newArray);

            setRequestedServicesIds([...requestedServicesIds, label.id]);
        }
    };

    const somaDosValores = requestServices.reduce((total, servico) => {
        // Adiciona o valor à soma apenas se for um número válido
        return isNaN(servico.value) ? total : total + servico.value;
    }, 0);

    const formatoBRL = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });

    const valorFormatado = formatoBRL.format(somaDosValores);

    const handleClose = () => setShow(false);

    const [showLogin, setShowLogin] = useState(false);
    const handleCloseLogin = () => setShowLogin(false);
    const handleShowLogin = () => setShowLogin(true);

    const [isVisible, setIsVisible] = useState(false);

    const toggleChat = () => {
        setIsVisible(!isVisible);
    };

    const goToLogin = (providerId) => {
        const url = `/login`;
        navigate(url);
    };

    return (
        <section className="py-0 bg-grey px-2">
            <Container fluid="xl ps-0 pe-0">
                <Col md={12} xl={12}>
                    <Container className="provider-header bg-white mb-4">
                        <div className="prestadio-background">
                            <img src={ProfileBackground} alt=""></img>
                        </div>
                        <div className="d-xl-flex provider-info align-items-center">
                            <div>
                                <div
                                    className="mb-3 mb-md-0 provider-picture"
                                    style={{
                                        position: 'relative',
                                        textAlign: 'center',
                                    }}
                                >
                                    <Image
                                        src={
                                            providerData?.profilePicture?.url
                                                ? providerData?.profilePicture?.url
                                                : UserImage
                                        }
                                        className={`rounded-circle avatar-xl mb-3 ${
                                            providerData?.userType === 'provider'
                                                ? 'userType-provider'
                                                : providerData?.prestadioTeam === true
                                                ? 'userType-prestadioTeam'
                                                : 'default-class-name'
                                        }`}
                                        alt=""
                                        style={{
                                            maxWidth: '120px', // Defina o valor máximo de largura desejado
                                            maxHeight: '120px', // Defina o valor máximo de altura desejado
                                            height: '120px',
                                            width: '120px',
                                            objectFit: 'cover',
                                        }}
                                    />

                                    {providerData?.userType === 'provider' ? (
                                        <Badge
                                            pill
                                            bg="primary"
                                            className="position-absolute translate-middle me-1 provider-badge"
                                        >
                                            Prestador
                                        </Badge>
                                    ) : providerData?.prestadioTeam === true ? (
                                        <Badge
                                            pill
                                            bg="black"
                                            className="position-absolute translate-middle me-1 provider-badge"
                                        >
                                            Team Prestadio
                                        </Badge>
                                    ) : (
                                        <Badge
                                            pill
                                            bg="primary"
                                            className="position-absolute translate-middle me-1 provider-badge"
                                        >
                                            Usuário
                                        </Badge>
                                    )}
                                </div>
                            </div>
                            {/* text */}
                            <div className="ms-xl-3  w-100 mt-0 mt-xl-0 mb-2">
                                <div className="d-flex justify-content-between mb-0 provider-description">
                                    <div className="provider-description">
                                        <div className="d-flex align-items-center desc gap-1">
                                            <h1 className="mb-0 h2">
                                                {_.startCase(_.toLower(providerData?.firstName))}{' '}
                                                {_.startCase(_.toLower(providerData?.lastName))}
                                            </h1>
                                            {providerData?.prestadioTeam != true ? (
                                                <div className="mb-0">
                                                    {/* star */}
                                                    <span
                                                        className="text-dark ms-2 gap-2"
                                                        style={{
                                                            marginRight: '5px',
                                                        }}
                                                    >
                                                        <strong>
                                                            {' '}
                                                            {providerData?.rating &&
                                                                providerData?.rating
                                                                    .toFixed(1)
                                                                    .replace('.', ',')}
                                                        </strong>

                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="10"
                                                            height="10"
                                                            fill="currentColor"
                                                            className="bi bi-star-fill text-warning align-baseline ms-1"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                        </svg>
                                                    </span>
                                                    <span className="ms-0">
                                                        ({recommendations?.length} Avaliações)
                                                    </span>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>

                                        <div className="mb-2 provider-description d-flex flex-column">
                                            <span className="mb-1">
                                                {providerData?.portfolioTitle}
                                            </span>
                                            {providerData?.prestadioTeam != true ? (
                                                <div>
                                                    {providerData?.skills
                                                        ?.slice(0, 5)
                                                        .map((skill, index) => (
                                                            <span
                                                                key={skill}
                                                                className="badge text-bg-primary skills-badge"
                                                            >
                                                                {_.startCase(_.toLower(skill))}
                                                            </span>
                                                        ))}
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                    {iAmTheProvider === false && login === true ? (
                                        <div className="d-flex flex-direction-row gap-1 profile-action-buttons">
                                            {isFollowing === false ? (
                                                <Button
                                                    className="d-flex gap-1 align-items-center action-button follow"
                                                    onClick={() => followUser()}
                                                    variant="primary"
                                                    size="sm"
                                                >
                                                    <i className="fe fe-user-plus"></i>
                                                    Seguir
                                                </Button>
                                            ) : (
                                                <Button
                                                    className="d-flex gap-1 align-items-center unfollow action-button"
                                                    onClick={() => unfollowUser()}
                                                    variant="light"
                                                    onMouseEnter={handleHover}
                                                    onMouseLeave={handleHover}
                                                    size="sm"
                                                >
                                                    {hovered === true ? (
                                                        <i className="fe fe-x"></i>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {hovered === true ? (
                                                        'Deixar de seguir'
                                                    ) : (
                                                        <div className="d-flex align-items-center gap-1">
                                                            <i className="fe fe-user-check"></i>
                                                            Seguindo
                                                        </div>
                                                    )}
                                                </Button>
                                            )}

                                            <Button
                                                onClick={() => {
                                                    sendFirstMessage(providerData._id);
                                                }}
                                                className="d-flex gap-1 align-items-center unfollow action-button message"
                                                size="sm"
                                            >
                                                <i className="fe fe-send"></i>
                                                Mensagem
                                            </Button>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>

                                {/* Descrição e endereço */}
                                <div>
                                    {providerData?.prestadioTeam != true ? (
                                        <div className="d-md-flex justify-content-between mb-0">
                                            <div className="mb-2 mb-md-0 provider-description">
                                                <span className="me-2">
                                                    <i className="fe fe-briefcase text-muted"></i>
                                                    <span className="ms-1 ">
                                                        {providerData?.occupationArea}
                                                    </span>
                                                </span>
                                                <span className="me-2">
                                                    <i className="fe fe-map-pin text-muted"></i>
                                                    <span className="ms-1 ">
                                                        {providerData?.city}, {providerData?.state}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                    </Container>

                    {providerData?.prestadioTeam != true ? (
                        <Card className="mb-4">
                            <Card.Header>
                                <h4>Solicitação de Serviço</h4>
                            </Card.Header>
                            <Card.Body>
                                <div className="mt-0 d-grid">
                                    <span className="mb-2">
                                        Está pronto para solicitar um serviço com{' '}
                                        {_.startCase(_.toLower(providerData?.firstName))}? Basta
                                        clicar no botão abaixo e iniciar o processo!
                                    </span>

                                    {/* <h3 className="mb-2 text-danger">
                                    No momento, a Prestadio está aberta apenas para cadastro de
                                    Portfólio. Em breve as solicitações de serviço serão liberadas 😀
                                    </h3> */}

                                    <Button
                                        as="a"
                                        variant="primary"
                                        href="#"
                                        onClick={() => (login ? setOpen(!open) : handleShowLogin())}
                                    >
                                        {open ? 'Formulário de Solicitação' : 'Solicitar serviço'}
                                    </Button>

                                    <Collapse in={open}>
                                        <Row className="mt-5">
                                            <Col className="mb-2">
                                                <div className="mt-2 d-grid">
                                                    {providerData?.services &&
                                                    providerData?.services.length > 0 ? (
                                                        <Col
                                                            xl={{ span: 12, offset: 0 }}
                                                            md={12}
                                                            className="mb-4"
                                                        >
                                                            <Card className="card-with-shadow">
                                                                <Card.Header>
                                                                    <h4 className="mb-2">
                                                                        Selecione o(s) serviço(s)
                                                                        desejado(s) e clique em
                                                                        "Enviar solicitação"
                                                                    </h4>
                                                                </Card.Header>
                                                                <Card.Body>
                                                                    <ListGroup variant="flush">
                                                                        {providerData.services.map(
                                                                            (service, index) => (
                                                                                <ListGroup.Item
                                                                                    className="d-flex justify-content-between align-items-center"
                                                                                    key={index}
                                                                                >
                                                                                    <div>
                                                                                        {' '}
                                                                                        <p className="mb-0">
                                                                                            {' '}
                                                                                            {
                                                                                                service.title
                                                                                            }
                                                                                        </p>
                                                                                        <h5>
                                                                                            {' '}
                                                                                            R${' '}
                                                                                            {
                                                                                                service.value
                                                                                            }
                                                                                        </h5>
                                                                                    </div>
                                                                                    <div>
                                                                                        <Form.Check
                                                                                            type="checkbox"
                                                                                            className="mb-2"
                                                                                            id={
                                                                                                index
                                                                                            }
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                        >
                                                                                            <Form.Check.Input
                                                                                                type="checkbox"
                                                                                                onChange={() =>
                                                                                                    handleServiceSelect(
                                                                                                        service
                                                                                                    )
                                                                                                }
                                                                                                name="occupationAreaGroup"
                                                                                            />
                                                                                        </Form.Check>
                                                                                    </div>
                                                                                </ListGroup.Item>
                                                                            )
                                                                        )}
                                                                        <ListGroup.Item className="d-flex justify-content-between">
                                                                            <div>
                                                                                <h4>
                                                                                    Valor Total:
                                                                                </h4>
                                                                            </div>
                                                                            <div>
                                                                                <h4>
                                                                                    {' '}
                                                                                    {valorFormatado}
                                                                                </h4>
                                                                            </div>
                                                                        </ListGroup.Item>
                                                                    </ListGroup>
                                                                    <div className="d-flex justify-content-end">
                                                                        <Button
                                                                            variant="primary"
                                                                            type="submit"
                                                                            onClick={handleSubmit(
                                                                                onSubmit
                                                                            )}
                                                                            disabled={loading}
                                                                            className="mb-4"
                                                                        >
                                                                            {loading && (
                                                                                <span
                                                                                    className="spinner-border spinner-border-sm me-2"
                                                                                    role="status"
                                                                                    aria-hidden="true"
                                                                                ></span>
                                                                            )}
                                                                            Enviar solicitação
                                                                        </Button>
                                                                    </div>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    ) : (
                                                        <p>Nenhum serviço adicionado ainda.</p>
                                                    )}
                                                </div>

                                                {submitSuccess === true ? (
                                                    <Alert variant="success" className="success">
                                                        Solicitação enviada com Sucesso!
                                                    </Alert>
                                                ) : submitSuccess === false ? (
                                                    <Alert variant="danger" className="danger">
                                                        {errorMessage}
                                                    </Alert>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    </Collapse>
                                </div>
                            </Card.Body>
                        </Card>
                    ) : (
                        <Card className="mb-4">
                            <Card.Header>
                                <h4>Informações</h4>
                            </Card.Header>
                            <Card.Body className="d-flex flex-column">
                                <span className="mb-2">
                                    <strong>Olá, {loggedUser?.firstName}</strong>
                                </span>
                                <span className="mb-4">
                                    Você está visualizando o perfil de um administrador da
                                    plataforma. Este perfil tem acesso privilegiado para gerenciar e
                                    supervisionar as operações da plataforma, garantindo que tudo
                                    funcione sem problemas e de acordo com as diretrizes
                                    estabelecidas.
                                </span>
                                <span className="mb-4">
                                    <strong>Tenho responsabilidades como:</strong>
                                    <ul>
                                        <li>Gerenciar usuários e permissões</li>
                                        <li>Monitorar a segurança da plataforma</li>
                                        <li>Analisar dados para melhorias contínuas</li>
                                        <li>Prestar suporte e orientação aos usuários</li>
                                    </ul>
                                </span>
                                <span className="mb-4">
                                    Tenha em mente que estou aqui para garantir uma experiência
                                    positiva para todos os usuários. Se precisar de assistência ou
                                    tiver alguma dúvida, sinta-se à vontade para entrar em contato
                                    comigo!😉
                                </span>
                                <span className="mb-4">
                                    Obrigado por confiar em nossa plataforma!{' '}
                                    <BalloonHeartFill className="text-primary" />
                                </span>

                                <span className="mb-1">Atenciosamente,</span>
                                <span className="mb-4">Equipe Prestadio</span>
                            </Card.Body>
                        </Card>
                    )}

                    {providerData?.prestadioTeam !== true ? (
                        <Card className="mb-4">
                            <Card.Body>
                                <h1 className="mb-1">Trabalhos</h1>
                                <div className="image-grid-list">
                                    {providerData?.portfolioPictures &&
                                        providerData?.portfolioPictures.map((item, index) => (
                                            <div className="image-container">
                                                <img
                                                    key={index}
                                                    src={item.url}
                                                    alt={`Imagem ${index + 1}`}
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        setShow(true);
                                                        setSelectedImage(item.url);
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    {!providerData?.portfolioPictures && 'Ainda não há fotos'}
                                </div>

                                <hr className="my-4" />

                                <div className="mt-5 d-grid">
                                    <h1 className="mb-1 h2 ">Sobre</h1>
                                    <div>{handleTextarea()}</div>
                                </div>

                                <hr className="my-4" />

                                <div className="mt-5 d-grid">
                                    <h1 className="mb-1 h2 ">Serviços</h1>
                                    {providerData?.services && providerData?.services.length > 0 ? (
                                        <Col xl={{ span: 12, offset: 0 }} md={12}>
                                            <ListGroup>
                                                {providerData?.services &&
                                                    providerData?.services.map((item, index) => {
                                                        return (
                                                            <ListGroup.Item
                                                                key={index}
                                                                className="d-flex align-items-center justify-content-between"
                                                            >
                                                                <div>
                                                                    <h4 className="mb-0">
                                                                        {item.title}
                                                                    </h4>
                                                                    <small>
                                                                        {item.description}
                                                                    </small>
                                                                </div>
                                                                <div className="d-flex flex-column align-items-end">
                                                                    <small>Valor</small>
                                                                    <p className=" mb-0 fw-bold text-primary">
                                                                        {item.value.toLocaleString(
                                                                            'pt-BR',
                                                                            {
                                                                                style: 'currency',
                                                                                currency: 'BRL',
                                                                            }
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </ListGroup.Item>
                                                        );
                                                    })}
                                            </ListGroup>
                                        </Col>
                                    ) : (
                                        <p>Nenhum serviço adicionado ainda.</p>
                                    )}
                                </div>

                                <hr className="my-4" />

                                <div className="mt-5 d-grid">
                                    <h1 className="mb-3 h2 ">Avaliações</h1>
                                    <div className="ratingCards">
                                        {recommendations && recommendations.length > 0
                                            ? recommendations.map((item, index) => (
                                                  <div className="rating">
                                                      <div className="ratingTitle">
                                                          <div className="d-flex flex-direction-row gap-3">
                                                              <Image
                                                                  src={
                                                                      item?.whoRecommended
                                                                          ?.profilePicture?.url
                                                                  }
                                                                  alt=""
                                                                  className="icon-shape border rounded-circle"
                                                                  style={{
                                                                      maxWidth: '50px', // Defina o valor máximo de largura desejado
                                                                      maxHeight: '50px', // Defina o valor máximo de altura desejado
                                                                  }}
                                                              />
                                                              <div className="evaluator">
                                                                  {' '}
                                                                  <Link
                                                                      to={`/prestadores/listagem/${item?.whoRecommended?._id}`}
                                                                  >
                                                                      <strong className="cursor-pointer">
                                                                          {
                                                                              item?.whoRecommended
                                                                                  ?.firstName
                                                                          }
                                                                      </strong>{' '}
                                                                      <strong className="cursor-pointer">
                                                                          {
                                                                              item?.whoRecommended
                                                                                  ?.lastName
                                                                          }
                                                                      </strong>
                                                                  </Link>
                                                                  <div className="ratingSubtitle flex-column">
                                                                      <small>
                                                                          {' '}
                                                                          {
                                                                              item?.whoRecommended
                                                                                  ?.city
                                                                          }
                                                                      </small>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                          <div className="d-flex flex-column">
                                                              <div className="d-flex gap-2 align-items-center">
                                                                  <small>Geral: </small>
                                                                  <span className="ratingValue">
                                                                      {item?.rating &&
                                                                          item?.rating
                                                                              .toFixed(1)
                                                                              .replace('.', ',')}
                                                                  </span>
                                                                  <svg
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                      width="10"
                                                                      height="10"
                                                                      fill="currentColor"
                                                                      className="bi bi-star-fill text-warning align-baseline"
                                                                      viewBox="0 0 16 16"
                                                                  >
                                                                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                                  </svg>
                                                                  <span className="ratingDate">
                                                                      {moment
                                                                          .utc(item?.createdAt)
                                                                          .format('DD/MM/YYYY')}
                                                                  </span>
                                                              </div>
                                                              <div>
                                                                  <small>Satisfação: </small>
                                                                  <span className="ratingValue">
                                                                      {item?.levelOfSatisfaction &&
                                                                          item?.levelOfSatisfaction
                                                                              .toFixed(1)
                                                                              .replace('.', ',')}
                                                                  </span>
                                                              </div>
                                                              <div>
                                                                  <small>Pontualidade: </small>
                                                                  <span className="ratingValue">
                                                                      {item?.providerPunctuality &&
                                                                          item?.providerPunctuality &&
                                                                          item?.providerPunctuality
                                                                              .toFixed(1)
                                                                              .replace('.', ',')}
                                                                  </span>
                                                              </div>
                                                              <div>
                                                                  <small>Qualidade: </small>
                                                                  <span className="ratingValue">
                                                                      {item?.serviceQuality &&
                                                                          item?.serviceQuality
                                                                              .toFixed(1)
                                                                              .replace('.', ',')}
                                                                  </span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div className="ratingText"></div>
                                                  </div>
                                              ))
                                            : 'O Prestador ainda não recebeu avaliações'}
                                    </div>

                                    {/* Lista de imagens do prestador */}
                                    <Modal show={show} onHide={handleClose} size="xl">
                                        <Modal.Header closeButton>
                                            Fotos de {providerData?.firstName}{' '}
                                            {providerData?.lastName}
                                        </Modal.Header>
                                        <Modal.Body className="modal-pictures-body">
                                            <Carousel indicators fade variant="dark">
                                                <Carousel.Item key={0}>
                                                    <img
                                                        src={selectedImage}
                                                        alt={`Imagem 0`}
                                                        style={{
                                                            objectFit: 'contain',
                                                            width: '100%',
                                                            height: '500px',
                                                        }}
                                                    />
                                                </Carousel.Item>
                                                {providerData?.portfolioPictures?.map(
                                                    (item, index) => (
                                                        <Carousel.Item key={index}>
                                                            <img
                                                                src={item.url}
                                                                alt={`Imagem ${index + 1}`}
                                                                style={{
                                                                    objectFit: 'contain',
                                                                    width: '100%',
                                                                    height: '500px',
                                                                }}
                                                            />
                                                        </Carousel.Item>
                                                    )
                                                )}
                                            </Carousel>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary" onClick={handleClose}>
                                                Fechar
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    {/* Login para solicitar um serviço */}
                                    <Modal show={showLogin} onHide={handleCloseLogin}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Autenticação</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            Para solicitar um serviço na <strong>Prestadio</strong>,
                                            é necessário fazer login em sua conta. Isso garante a
                                            segurança de suas informações e permite uma experiência
                                            personalizada. Por favor, clique no botão abaixo para
                                            fazer login:
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleCloseLogin}>
                                                Cancelar
                                            </Button>
                                            <Button variant="primary" onClick={goToLogin}>
                                                Fazer login
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </Card.Body>
                        </Card>
                    ) : (
                        ''
                    )}
                </Col>
            </Container>

            {showPayment && (
                <Payment
                    name={`${_.startCase(_.toLower(providerData?.firstName))} ${_.startCase(
                        _.toLower(providerData?.lastName)
                    )}`}
                    serviceValue={somaDosValores}
                    recipientId={providerData?._id}
                    isService={true}
                    onClose={() => setShowPayment(false)}
                />
            )}
        </section>
    );
};

export default ServiceProviderProfile;
