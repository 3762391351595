// import node module libraries
import { Fragment } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Col, Row, Card, Form, Button, Image, InputGroup, Alert } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import axios from 'axios';
import '../../../components/Style.css';
import { useForm, Controller } from 'react-hook-form';

import { differenceInYears, parse } from 'date-fns';

// import media files
import Logo from 'assets/images/brand/logo/logo_prestadio_grande.png';
import AuthBackground from 'assets/images/background/auth_background.png';
import ReactInputMask from 'react-input-mask';
import PasswordStrengthBar from 'react-password-strength-bar';

import occupationAreas from 'components/serviceProviders/occupationAreas';
import { FormSelect } from 'components/elements/form-select/FormSelect';

import cep from 'cep-promise';
import { debounce } from 'lodash';

import { jwtDecode } from 'jwt-decode';

const ChangePassword = (props) => {
    const navigate = useNavigate();
    const [submitSuccefull, setSubmitSuccefull] = useState('');
    const [submitError, setSubmitError] = useState('');
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [passwordPass, setPasswordPass] = useState(false);

    const url = process.env.REACT_APP_API_URL;

    const currentUrl = window.location.href;

    const hash = currentUrl.split('hash=')[1];

    const config = {
        headers: {
            Authorization: `Bearer ${hash}`,
            ['x-authorization-hash']: hash,
        },
    };

    hash;

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm();

    // Defina a função que receberá o valor do CEP

    const onSubmit = async (data) => {
        setLoading(true);

        if (password === passwordConfirm) {
            setSubmitError('');
            setSubmitSuccefull('');
            setLoading(true);

            const dataForm = {
                password: password,
            };

            axios
                .patch(`${url}accounts/users/change-password`, dataForm, config)

                .then((response) => {
                    setLoading(false);
                    navigate('/authentication/password-set');
                })

                .catch((error) => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
            setPasswordPass(false);
        }
    };

    const [viewPassword, setViewPassword] = useState(false);
    const [viewConfirmPassword, setViewConfirmPassword] = useState(false);

    return (
        <Fragment>
            <Row className="align-items-center justify-content-center g-0 min-vh-100">
                <Col lg={6} md={6} className="py-8 py-xl-0">
                    <Card>
                        <Card.Body className="p-6">
                            <div className="mb-0">
                                <Link to="/">
                                    <div id="div-logo-grande">
                                        <Link to="/">
                                            <Image
                                                src={Logo}
                                                className="mb-1"
                                                alt=""
                                                id="logo-grande"
                                            />
                                        </Link>
                                    </div>
                                    <hr className="my-4" />
                                </Link>
                                <h2 className="mb-1 fw-bold">Redefinir senha</h2>
                                <span>Informe sua nova senha</span>
                            </div>
                            {/* Form */}
                            <Form>
                                <Row className="">
                                    <Col lg={6} md={6} xl={6} className="mt-3">
                                        <InputGroup>
                                            <Form.Control
                                                type={viewPassword === true ? 'text' : 'password'}
                                                id="password"
                                                placeholder="Senha"
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                            <InputGroup.Text
                                                id="basic-addon1"
                                                className="cursor-pointer"
                                                onClick={() => setViewPassword(!viewPassword)}
                                            >
                                                {viewPassword === true ? (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        class="bi bi-eye"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        class="bi bi-eye-slash"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                                        <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                                                    </svg>
                                                )}
                                            </InputGroup.Text>
                                        </InputGroup>

                                        <PasswordStrengthBar
                                            className="password_strength"
                                            scoreWords={[
                                                'Fraca',
                                                'Média',
                                                'Forte',
                                                'Muito Forte',
                                                'Fortíssima',
                                            ]}
                                            minLenght={8}
                                            shortScoreWord="Muito pequena"
                                            password={password}
                                        />
                                        {password.length == undefined && (
                                            <p className="errorMessageInput">Campo obrigatório*</p>
                                        )}
                                    </Col>

                                    <Col lg={6} md={6} xl={6} className="mt-3 mb-4">
                                        <InputGroup>
                                            <Form.Control
                                                type={
                                                    viewConfirmPassword === true
                                                        ? 'text'
                                                        : 'password'
                                                }
                                                id="password-confirm"
                                                placeholder="Confirmar senha"
                                                onChange={(e) => setPasswordConfirm(e.target.value)}
                                            />
                                            <InputGroup.Text
                                                id="basic-addon1"
                                                className="cursor-pointer"
                                                onClick={() =>
                                                    setViewConfirmPassword(!viewConfirmPassword)
                                                }
                                            >
                                                {viewConfirmPassword === true ? (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        class="bi bi-eye"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        class="bi bi-eye-slash"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                                        <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                                                    </svg>
                                                )}
                                            </InputGroup.Text>
                                        </InputGroup>
                                    </Col>
                                    {passwordConfirm != password && (
                                        <p className="errorMessageInput">As senhas não conferem*</p>
                                    )}

                                    <Col lg={12} md={12} className="mb-0 d-grid gap-2">
                                        {/* Button */}
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            disabled={loading}
                                            onClick={handleSubmit(onSubmit)}
                                            style={{ color: 'white' }}
                                        >
                                            {loading && (
                                                <span
                                                    className="spinner-border spinner-border-sm me-2"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            )}
                                            Alterar senha
                                        </Button>
                                        {submitError.length > 0 && (
                                            <Col className="submitError">
                                                <Alert variant="danger">{submitError}</Alert>
                                            </Col>
                                        )}
                                        {submitSuccefull.length > 0 && (
                                            <Col className="submitError">
                                                <Alert variant="success">
                                                    Senha alterada com sucesso!
                                                </Alert>
                                            </Col>
                                        )}
                                        {passwordPass == true && (
                                            <Col className="submitError">
                                                <p>As senhas não conferem!</p>
                                            </Col>
                                        )}
                                    </Col>
                                </Row>
                            </Form>
                            {/*<hr className="my-4" />
                            <div className="mt-4 text-center">
                               
                                <Link
                                    to="#"
                                    className="btn-social btn-social-outline btn-facebook"
                                >
                                    <i className="fab fa-facebook"></i>
                                </Link>{' '}
                                
                                <Link
                                    to="#"
                                    className="btn-social btn-social-outline btn-google"
                                >
                                    <i className="fab fa-google"></i>
                                </Link>{' '}
                            </div>*/}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default ChangePassword;
