/* eslint-disable import/no-unused-modules */
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { onSnapshot } from 'firebase/firestore';
import axios from 'axios';

import { current } from '@reduxjs/toolkit';
import auth from 'functions/checkLogin';

import {
    Form,
    Image,
    InputGroup,
    Spinner,
    Button,
    Modal,
    Table,
    ListGroup,
    Badge,
} from 'react-bootstrap';
import {
    LightningCharge,
    CurrencyDollar,
    QuestionCircle,
    ChatDots,
    Eye,
    Unlock,
    ArrowClockwise,
    WalletFill,
    Clock,
    Check2Circle,
    Translate,
    Wallet,
    Lock,
    Check2All,
} from 'react-bootstrap-icons';

import Spinners from 'components/elements/bootstrap/Spinners';
import UserImage from 'assets/images/avatar/user.png';
import { useMessage } from './useMessage';
import Payment from '../../../Payment/Payment';

const ChatBottom = ({ chatData }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const chatBodyRef = useRef(null);
    const translatedMessagesRef = useRef({});

    const url = process.env.REACT_APP_API_URL;
    const googleKey = process.env.REACT_APP_GOOGLE;

    const { control, handleSubmit, reset } = useForm();

    const [messages, setMessages] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const [minimize, setMinimize] = useState(false);
    const [loading, setLoading] = useState(true);
    const [fastService, setFastService] = useState(false);
    const [loggedUser, setLoggedUser] = useState();
    const [chatMode, setChatMode] = useState('text');
    const [forceUpdate, setForceUpdate] = useState(0);
    const [loadingStates, setLoadingStates] = useState({});
    const [activeChat, setActiveChat] = useState();
    const [chat, setChat] = useState();

    const currentChat = useSelector((rootReducer) => rootReducer.chatReducer.currentChat);
    const currentChatRef = useRef(currentChat);

    useEffect(() => {
        currentChatRef.current = currentChat;
    }, [currentChat]);

    const hash = localStorage.getItem('hash');
    const myChat = localStorage.getItem('CurrentChat');
    const loggedUserData = JSON.parse(localStorage.getItem('userData'));

    const config = {
        headers: {
            Authorization: `Bearer ${hash}`,
        },
    };

    const getLoggedUser = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        await axios
            .get(`${url}accounts/authentication/logged-user`, config)
            .then(function (response) {
                setLoggedUser(response.data);
            })
            .catch(function (error) {});
    };

    const { sendMessage, chatDoc, sendFile } = useMessage(
        currentChat?.metadata?._chatId || currentChat?._id,
        loggedUser,
        myChat
    );

    useEffect(() => {
        if (chatDoc) {
            const unsubscribe = onSnapshot(chatDoc, (_doc) => {
                const data = _doc.data();

                if (data) {
                    setMessages(data.messages);
                }
            });

            return unsubscribe;
        }
    }, [chatDoc]);

    useEffect(() => {
        setLoading(true);
        getLoggedUser();
        setLoading(false);
    }, []);

    useEffect(() => {
        if (currentChat != null) {
            setIsVisible(true);
        }
    }, [currentChat]);

    const onSubmit = (data) => {
        const myMessage = data.text.toString();

        sendMessage(myMessage);

        reset({ text: '' });
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (chatBodyRef.current) {
                chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
            }
        }, 100);

        return () => clearTimeout(timeoutId);
    }, [messages, isVisible]);

    const toggleChat = () => {
        setIsVisible(!isVisible);
    };

    const closeChat = () => {
        setIsVisible(!isVisible);

        dispatch({ type: 'chat/changeChat', payload: null });
    };

    const minimizeChat = () => {
        setMinimize(!minimize);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSubmit(onSubmit)();
        }
    };

    const loadingElement = () => {
        return (
            <div className="text-center">
                <Spinner animation="border" role="status"></Spinner>
            </div>
        );
    };

    const goToProvider = () => {
        const providerId = currentChat?.metadata?.authorId
            ? currentChat?.metadata?.authorId
            : currentChat?.usersDatas?.filter(({ _id }) => _id !== currentChat?.user?._id)[0]._id;
        const url = `/prestadores/listagem/${providerId}`;

        navigate(url);
    };

    const ChatDivider = ({ date }) => {
        return (
            <div className="chat-divider">
                <span>{date}</span>
            </div>
        );
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('pt-BR', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };

    const traduzirTexto = async (message, item) => {
        var messageId = item._id;
        var message = message.props.children;

        const params = {
            q: message, // Texto a ser traduzido
            target: 'pt', // Idioma alvo
            key: googleKey, // Sua chave de API
        };

        const config = {
            params: params,
        };

        setLoadingStates((prevLoadingStates) => ({
            ...prevLoadingStates,
            [item._id]: true,
        }));

        await axios
            .post(`https://translation.googleapis.com/language/translate/v2`, null, config)
            .then(function (response) {
                const translatedText = response.data.data.translations[0].translatedText;
                translatedMessagesRef.current[item._id] = translatedText;
                setForceUpdate(forceUpdate + 1);

                setLoadingStates((prevLoadingStates) => ({
                    ...prevLoadingStates,
                    [item._id]: false,
                }));
            })
            .catch(function (error) {});
    };

    const createFastService = () => {
        setFastService((currentState) => !currentState);

        const timeoutId = setTimeout(() => {
            if (chatBodyRef.current) {
                chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
            }
        }, 100);
    };

    const [payment, setPayment] = useState();
    const [waitingPayment, setWaitingPayment] = useState(false);
    const [paymentLink, setPaymentLink] = useState();
    const [loadingPayment, setLoadingPayment] = useState(false);
    const sendPayment = () => {
        setLoadingPayment(true);
        setWaitingPayment(true);

        const data = {
            value: parseFloat(payment),
            destinationUserId: currentChat._id,
        };
        axios
            .post(`${url}messages/payment`, data, config)
            .then(function (response) {
                setPaymentLink(response.data.link);
                window.open(response.data.link, '_blank');
                setLoadingPayment(false);
            })
            .catch(function (error) {});
    };

    const divFastService = () => {
        return (
            <div className="chat-fast-service">
                <div className="fast-service-description">
                    <strong className="fast-service-title">
                        <LightningCharge /> Serviço rápido{' '}
                    </strong>
                    <hr></hr>
                    <strong className="fast-service-title">
                        <QuestionCircle /> Como funciona?
                    </strong>
                    <span>
                        Se você já combinou o valor do serviço que deseja com o Prestador, pode
                        enviar o valor combinado por meio da contratação rápida. <br></br>É fácil e
                        seguro! O dinheiro fica com a Prestadio até você confirmar a realização do
                        serviço!
                    </span>
                </div>
                <hr></hr>
                <div className="fast-service-form">
                    <strong className="fast-service-title">
                        <CurrencyDollar /> Enviar dinheiro
                    </strong>
                    <span>
                        Qual valor você deseja enviar para{' '}
                        <strong>{currentChat?.usersDatas?.[1]?.firstName}?</strong>
                    </span>
                    <Form.Control
                        type="number"
                        placeholder=""
                        onChange={(e) => setPayment(e.target.value)}
                        disabled={loadingPayment}
                    />
                    <Button onClick={() => sendPayment()}>
                        {loadingPayment && (
                            <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        )}
                        Enviar
                    </Button>
                </div>
            </div>
        );
    };

    const backToChat = () => {
        setWaitingPayment(false);
    };

    const divFinishPayment = () => {
        return (
            <div className="chat-fast-service">
                <div className="fast-service-description">
                    <strong className="fast-service-title">
                        <QuestionCircle /> Pagamento
                    </strong>
                    <span>
                        O <a href={paymentLink}>Link de pagamento</a> do Mercado Pago foi aberto em
                        uma nova aba do seu navegador. Lá, você pode efetuar o pagamento da maneira
                        que preferir 😊
                    </span>
                </div>
                <hr></hr>
                <div className="fast-service-form">
                    <span>
                        <strong className="text-info">
                            Após efetuar o pagamento, o valor é retido pela Prestadio até que você
                            confirme a conclusão do serviço!
                        </strong>
                    </span>
                    <span>
                        Para confirmar a realização do serviço e liberar o pagamento para o
                        prestador,{' '}
                        <strong>
                            basta clicar no ícone da carteira <WalletFill />
                        </strong>{' '}
                        no menu superior do chat 😉
                    </span>
                    <Button onClick={() => backToChat()}>Voltar para o chat</Button>
                </div>
            </div>
        );
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [payments, setPayments] = useState();

    const getPayments = async () => {
        setLoadingPayments(true);
        if (currentChat?._id) {
            await axios
                .get(`${url}messages/payment/user/${currentChat?._id}`, config)
                .then(function (response) {
                    setPayments(response.data);
                    setLoadingPayments(false);
                })
                .catch(function (error) {});
        }
    };

    useEffect(() => {
        getPayments();
    }, [show]);

    const [loadingPayments, setLoadingPayments] = useState(false);
    const [loadingRelease, setLoadingRelease] = useState();
    var releasingPaymentId = 0;
    const releasePayment = async (paymentId) => {
        await axios
            .patch(`${url}messages/payment/${paymentId}`, '', config)
            .then(function (response) {
                setLoadingRelease(false);
                getPayments();
            })
            .catch(function (error) {
                setLoadingRelease(false);
                getPayments();
            });
    };

    const releaseButton = (item) => {
        if (item.payment.status === 'PAID') {
            return (
                <Button
                    size="xs"
                    className="d-flex align-items-center gap-1"
                    id={item.payment._id}
                    disabled={item.payment.status == 'PENDING'}
                    onClick={() => {
                        releasePayment(item.payment._id);
                        setLoadingRelease(item.payment._id);
                    }}
                    style={{
                        width: '90px', // Define a fonte em negrito
                    }}
                >
                    {loadingRelease == item.payment._id ? (
                        <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                        ></span>
                    ) : (
                        <Unlock />
                    )}
                    Liberar
                </Button>
            );
        } else if (item.payment.status == 'APPROVED') {
            return (
                <Button
                    size="xs"
                    pill
                    bg="success"
                    className="me-1 d-flex align-items-center gap-1 btn-success"
                    disabled
                    style={{
                        width: '90px', // Define a fonte em negrito
                    }}
                >
                    <Check2Circle />
                    Liberado
                </Button>
            );
        } else if (item.payment.status == 'PENDING') {
            return (
                <Button
                    size="xs"
                    className="d-flex align-items-center gap-1 btn-warning"
                    style={{
                        width: '90px', // Define a fonte em negrito
                    }}
                    id={item.payment._id}
                    disabled
                >
                    <Clock />
                    Pendente
                </Button>
            );
        }
    };

    let prevDate = null;

    const [showModalPayment, setShowModalPayment] = useState(false);

    const openPayment = () => {
        setShowModalPayment(true);
    };

    return (
        <>
            <div>
                <Modal show={show} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Pagamentos para {currentChat?.usersDatas?.[1]?.firstName}{' '}
                            {currentChat?.usersDatas?.[1]?.lastName}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div class="table-responsive">
                            <Table hover className="text-nowrap table-sm">
                                <thead>
                                    <tr>
                                        <th scope="col">Valor</th>
                                        <th scope="col">Link</th>
                                        <th scope="col">Liberar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {payments &&
                                        payments.map((item) => (
                                            <tr>
                                                <th scope="row">R${item?.payment?.value}</th>
                                                <th scope="row">
                                                    <span
                                                        size="sm"
                                                        className="d-flex align-items-center gap-1 cursor-pointer"
                                                        style={{ fontSize: '14px' }}
                                                        onClick={() =>
                                                            window.open(
                                                                item?.payment?.link,
                                                                '_blank'
                                                            )
                                                        }
                                                    >
                                                        <Eye />
                                                        Visualizar
                                                    </span>
                                                </th>
                                                <th scope="row d-flex justify-content-center">
                                                    {releaseButton(item)}
                                                </th>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>
                        </div>

                        <Button
                            size="xs"
                            className="d-flex align-items-center gap-1"
                            onClick={() => getPayments()}
                        >
                            {loadingPayments == true ? (
                                <span
                                    className="spinner-border spinner-border-sm me-2"
                                    role="status"
                                    size="sm"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                <ArrowClockwise />
                            )}
                            Atualizar
                        </Button>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>

                {isVisible && (
                    <div className="chat-box">
                        <div className="chat-header">
                            <div
                                className="d-flex align-items-center gap-2 cursor-pointer"
                                onClick={() => goToProvider()}
                            >
                                <div className="avatar avatar-sm">
                                    <Image
                                        alt="avatar"
                                        src={
                                            currentChat?.usersDatas?.[1]?.profilePicture
                                                ? currentChat?.usersDatas[1].profilePicture
                                                : currentChat?.metadata?.authorProfilePictureUrl
                                                ? currentChat?.metadata?.authorProfilePictureUrl
                                                : UserImage
                                        }
                                        className="rounded-circle feed-avatar"
                                    />
                                </div>

                                <div className="header-title cursor-pointer">
                                    <h4 className="mb-0">
                                        {`${
                                            currentChat?.usersDatas?.filter(
                                                ({ _id }) => _id !== currentChat?.user?._id
                                            )
                                                ? currentChat?.usersDatas.filter(
                                                      ({ _id }) => _id !== currentChat?.user?._id
                                                  )[0].firstName
                                                : currentChat?.metadata?.authorFirstName
                                        } ${
                                            currentChat?.usersDatas.filter(
                                                ({ _id }) => _id !== currentChat?.user?._id
                                            )[0].lastName || ''
                                        }`}
                                    </h4>
                                    <small className="d-flex">{currentChat?.portfolioTitle}</small>
                                </div>
                            </div>

                            <div className="d-flex align-items-center gap-2">
                                <WalletFill className="cursor-pointer" onClick={handleShow} />
                                <i
                                    className={`fe ${
                                        minimize === false ? 'fe-chevron-down' : 'fe-chevron-up'
                                    } cursor-pointer`}
                                    style={{ fontSize: '24px' }}
                                    onClick={minimizeChat}
                                ></i>
                                <i
                                    className="fe fe-x cursor-pointer"
                                    style={{ fontSize: '24px' }}
                                    onClick={closeChat}
                                ></i>
                            </div>
                        </div>

                        <div
                            className={`chat-subheader ${minimize === false ? 'd-flex' : 'd-none'}`}
                        >
                            <Button onClick={() => createFastService()}>
                                {fastService ? <ChatDots /> : <LightningCharge />}
                                {fastService ? 'Chat' : 'Contratação rápida'}
                            </Button>
                        </div>

                        {loading == false ? (
                            <div
                                className={`chat-body ${minimize == false ? '' : 'd-none'}`}
                                ref={chatBodyRef}
                            >
                                {fastService == true && waitingPayment == false ? (
                                    divFastService()
                                ) : waitingPayment == true ? (
                                    divFinishPayment()
                                ) : (
                                    <div className="chat-messages">
                                        {messages
                                            ?.slice()
                                            .sort(
                                                (a, b) =>
                                                    new Date(a.createdAt) - new Date(b.createdAt)
                                            )
                                            .map((item, index) => {
                                                const toDate = item?.createdAt?.toDate();
                                                const currentDate = format(toDate, 'dd/MM');

                                                let divider = null;

                                                if (currentDate !== prevDate) {
                                                    divider = <ChatDivider date={currentDate} />;
                                                    prevDate = currentDate;
                                                } else if (!item.createdAt) {
                                                    divider = '';
                                                    prevDate = prevDate;
                                                }

                                                // document.addEventListener(
                                                //     'DOMContentLoaded',
                                                //     function () {
                                                //         const videoElement =
                                                //             document.getElementById('myVideo');
                                                //         const sourceElement =
                                                //             document.getElementById('videoSource');

                                                //         const videoUrl = item.fileUrl;

                                                //         sourceElement.src = videoUrl;
                                                //         videoElement.load();
                                                //     }
                                                // );

                                                const isFile = item?.fileUrl;
                                                const message = isFile ? (
                                                    <div className="">
                                                        <a
                                                            href={item?.fileUrl}
                                                            className="d-flex align-items-center justify-content-between gap-2 flex-row file"
                                                        >
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                {(item.fileUrl.includes('.jpg') ||
                                                                    item.fileUrl.includes('.png') ||
                                                                    item.fileUrl.includes(
                                                                        '.jpeg'
                                                                    ) ||
                                                                    item.fileUrl.includes(
                                                                        '.svg'
                                                                    )) && (
                                                                    <img
                                                                        src={item?.fileUrl}
                                                                        alt=""
                                                                        style={{
                                                                            width: '220px',
                                                                            height: '220px',
                                                                            objectFit: 'cover',
                                                                        }}
                                                                    />
                                                                )}

                                                                {/* {item.fileUrl.includes('.MOV') && (
                                                                <video
                                                                    id="myVideo"
                                                                    width="220"
                                                                    height="200"
                                                                    controls
                                                                >
                                                                    <source
                                                                        id="videoSource"
                                                                        type="video/*"
                                                                    />
                                                                </video>
                                                            )} */}

                                                                <div
                                                                    style={{
                                                                        width: '100%',
                                                                        display: 'flex',
                                                                        justifyContent:
                                                                            'space-between',
                                                                        alignItems: 'center',
                                                                        gap: '1rem',
                                                                    }}
                                                                >
                                                                    {item?.file}{' '}
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="16"
                                                                        height="16"
                                                                        fill="black"
                                                                        className="bi bi-download"
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                                                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                ) : (
                                                    <p>{item?.message}</p>
                                                );

                                                if (loggedUser?._id === item?.userId) {
                                                    return (
                                                        <div className="message right" key={index}>
                                                            {divider}
                                                            <div className="message-description-right">
                                                                {message}
                                                            </div>
                                                        </div>
                                                    );
                                                }

                                                return (
                                                    <div className="message left" key={index}>
                                                        {divider}
                                                        <div className="message-description-left">
                                                            <p>
                                                                {translatedMessagesRef.current[
                                                                    item._id
                                                                ] || message}
                                                            </p>
                                                            {loadingStates[item._id] ? (
                                                                <Spinner
                                                                    animation="border"
                                                                    size="sm"
                                                                    className="me-2"
                                                                />
                                                            ) : (
                                                                <Translate
                                                                    title="Traduzir texto"
                                                                    onClick={() =>
                                                                        traduzirTexto(message, item)
                                                                    }
                                                                ></Translate>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                )}
                            </div>
                        ) : (
                            loadingElement()
                        )}

                        {fastService ? (
                            ''
                        ) : (
                            <div
                                className={`chat-bottom-footer ${
                                    minimize === false ? 'd-flex' : 'd-none'
                                }`}
                            >
                                <i className="fe fe-dollar-sign" onClick={openPayment}></i>

                                <Controller
                                    name="text"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: 'Este campo é obrigatório' }}
                                    render={({ field }) => {
                                        if (chatMode === 'text') {
                                            return (
                                                <InputGroup className="cursor-pointer">
                                                    <Form.Control
                                                        type="text"
                                                        id="text"
                                                        className="message-input"
                                                        placeholder="Aa"
                                                        onKeyDown={handleKeyDown}
                                                        {...field}
                                                    />
                                                    <InputGroup.Text
                                                        onClick={() => setChatMode('file')}
                                                    >
                                                        <i className="fe fe-paperclip"></i>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            );
                                        }

                                        if (chatMode === 'file') {
                                            return (
                                                <InputGroup className="cursor-pointer">
                                                    <Form.Control
                                                        id="company-logo"
                                                        type="file"
                                                        placeholder="Enviar imagem"
                                                        onChange={(e) => {
                                                            setChatMode('loading');
                                                            console.log(e.target.files[0]);
                                                            sendFile(e.target.files[0]);
                                                            setChatMode('text');
                                                        }}
                                                    />
                                                    <InputGroup.Text
                                                        onClick={() => setChatMode('text')}
                                                    >
                                                        <i className="fe fe-type"></i>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            );
                                        }

                                        if (chatMode === 'loading') {
                                            return (
                                                <div>
                                                    <span
                                                        className="spinner-border spinner-border-sm me-2"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                    <span>Enviando arquivo</span>
                                                </div>
                                            );
                                        }
                                    }}
                                />

                                {chatMode === 'loading' ? null : (
                                    <i className="fe fe-send" onClick={handleSubmit(onSubmit)}></i>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>

            {showModalPayment && (
                <Payment
                    onClose={() => setShowModalPayment(false)}
                    recipientId={
                        currentChat?.metadata?.authorId
                            ? currentChat?.metadata?.authorId
                            : currentChat?.usersDatas?.filter(
                                  ({ _id }) => _id !== currentChat?.user?._id
                              )[0]._id
                    }
                    name={`${
                        currentChat?.usersDatas?.filter(({ _id }) => _id !== currentChat?.user?._id)
                            ? currentChat?.usersDatas.filter(
                                  ({ _id }) => _id !== currentChat?.user?._id
                              )[0].firstName
                            : currentChat?.metadata?.authorFirstName
                    } ${
                        currentChat?.usersDatas.filter(
                            ({ _id }) => _id !== currentChat?.user?._id
                        )[0].lastName || ''
                    }`}
                />
            )}
        </>
    );
};

export default ChatBottom;
