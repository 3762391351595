// import node module libraries
import { useLocation } from 'react-router-dom';
import { Card, Form, Row, Col, Button, Image, Alert, Modal } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import React from 'react';
import AvatarEditor from 'react-avatar-editor';

// import custom components
// import { FormSelect } from 'components/elements/form-select/FormSelect';
// import { FlatPickr } from 'components/elements/flat-pickr/FlatPickr';

// import profile layout wrapper
import ProfileLayout from 'components/marketing/student/ProfileLayout';

import './styles/styles.css';

import axios from 'axios';

import { useForm } from 'react-hook-form';

import ReactInputMask from 'react-input-mask';

import '../../../components/Style.css';

import UserImage from 'assets/images/avatar/user.png';

// import { TagsInput } from 'react-tag-input-component';

import cep from 'cep-promise';

// import occupationAreas from 'components/serviceProviders/occupationAreas';

const EditProfile = () => {
    const editorRef = useRef(null);
    const pathInfo = useLocation();
    const account = pathInfo.pathname.substring(21, 11);
    // const [userData, setUserData] = useState([]);
    // const [updateImage, setUpdateImage] = useState();
    const [loading, setLoading] = useState(false);
    const [imageLoaded, setImageLoaded] = useState();
    // const [newImage, setNewImage] = useState();
    // const [skills, setSkills] = useState([]);
    const [successMessage, setSuccessMessage] = useState();
    const [errorMessage, setErrorMessage] = useState();
    // const [submitSuccefull, setSubmitSuccefull] = useState('');
    // const [submitError, setSubmitError] = useState('');
    // const [editedFile, setEditedFile] = useState(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [cpf, setCpf] = useState('');
    // const [loggedUserId, setLoggedUserId] = useState('');

    const url = process.env.REACT_APP_API_URL;
    const hash = localStorage.getItem('hash');
    //const loggedUser = localStorage.getItem('lworder_user_data');

    const [loggedUser, setLoggedUser] = useState();

    const getLoggedUserData = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        await axios
            .get(`${url}accounts/authentication/logged-user`, config)
            .then(function (response) {
                setLoggedUser(response.data);
                console.log('Dados do usuario', response.data);
            })
            .catch(function (error) {});
    };

    const handleCEPChange = async (cepValue) => {
        setLoading(true);
        try {
            // Faça o que precisar com o valor do CEP aqui
            const result = await cep(cepValue);
            return result;
        } catch (error) {
            setLoading(false);
        }
    };

    async function onSubmit(data) {
        setLoading(true);
        const cepResult = await handleCEPChange(data.cep);

        const newcpf = cpf.replace(/[^a-zA-Z0-9 ]/g, '');
        console.log('data', data);
        console.log('newcpf', newcpf);
        if (cepResult) {
            axios({
                method: 'patch',
                url: `${url}accounts/users/${loggedUser._id}`,
                headers: {
                    Authorization: `Bearer ${hash}`,
                },

                data: {
                    firstName: data.firstName === '' ? loggedUser.firstName : data.firstName,
                    lastName: data.lastName === '' ? loggedUser.lastName : data.lastName,
                    phone: data.phone === '' ? loggedUser.phone : data.phone,
                    birthday: data.birthday === '' ? loggedUser.birthday : data.birthday,

                    cpf: newcpf === '' ? loggedUser.cpf : cpf,
                    cep: cepResult.cep === '' ? loggedUser.cep : cepResult.cep,
                    state: cepResult.state === '' ? loggedUser.state : cepResult.state,
                    city: cepResult.city === '' ? loggedUser.city : cepResult.city,
                },
            })
                .then((response) => {
                    setLoading(false);
                    setSuccessMessage('Perfil atualizado com sucesso!');
                    getLoggedUserData();
                })
                .catch((error) => {
                    console.error(error);
                    setErrorMessage('Erro ao atualizar perfil. Tente novamente mais tarde.');
                    setLoading(false);
                });
        } else {
            setErrorMessage('CEP Inválido');
        }
    }

    const [image, setImage] = useState();

    const sendProfileImage = (e) => {
        setShow(true);
        setImage(e.target.files[0]);
    };

    const handleSave = async () => {
        setLoading(true);
        // Use o editorRef para obter os dados da imagem após a edição
        const canvas = editorRef.current.getImage();

        // Converta o canvas para um formato de dados de URL
        const dataUrl = canvas.toDataURL();

        // Converta a URL base64 para um objeto Blob
        const blob = dataURLtoBlob(dataUrl);

        // Crie um novo File a partir do Blob
        const editedFile = new File([blob], 'edited_avatar.png', {
            type: 'image/png',
        });

        // Faça algo com o editedFile, como enviar para o servidor ou armazenar no estado
        //setEditedFile(editedFile);

        setImageLoaded();

        // const { files } = editedFile;
        const newFormData = new FormData();

        newFormData.append('file', editedFile);

        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        await axios
            .post(`${url}accounts/users/update-profile-picture`, newFormData, config)

            .then((response) => {
                getLoggedUserData();
                setImageLoaded(true);
                setShow(false);
                setLoading(false);
            })

            .catch((error) => {
                setImageLoaded(false);
                setLoading(false);
            });
    };

    const dataURLtoBlob = (dataUrl) => {
        const arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    useEffect(() => {
        getLoggedUserData();
    }, []);

    const onLinkAccount = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        await axios.post(`${url}stripe/recipient`, {}, config);
    };

    return (
        <ProfileLayout>
            {account === 'instructor'}
            <Card className="border-0">
                <Card.Header>
                    <div className="mb-3 mb-lg-0">
                        <h3 className="mb-0">Meus dados</h3>
                        <p className="mb-0">Aqui você pode editar suas informações</p>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="d-lg-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center mb-4 mb-lg-0">
                            <Image
                                src={
                                    loggedUser?.profilePicture
                                        ? loggedUser.profilePicture.url
                                        : UserImage
                                }
                                id="img-uploaded"
                                className="avatar-xl rounded-circle"
                                alt=""
                            />
                            <div className="ms-3">
                                <h4 className="mb-0">Imagem de perfil</h4>
                                <p className="mb-0">PNG ou JPG menor ou igual a 800px.</p>
                            </div>
                        </div>

                        <div className="edit_image_profile">
                            <Form.Control
                                id="company-logo"
                                type="file"
                                placeholder="Enviar imagem"
                                onChange={(e) => sendProfileImage(e)}
                            />
                            {imageLoaded === true ? (
                                <p className="mb-0">Imagem do Perfil alterada com Sucesso!</p>
                            ) : imageLoaded === false ? (
                                <p className="mb-0 text-danger">Erro ao editar imagem do perfil</p>
                            ) : null}
                        </div>
                    </div>
                </Card.Body>
                <Card.Body className="border-top">
                    <div>
                        <h4 className="mb-3">Informações pessoais</h4>
                        <Row>
                            <Col md={6} sm={12} className="mb-3">
                                <h5 className="mb-0">Primeiro nome</h5>
                                <p className="mb-4">
                                    {loggedUser?.firstName
                                        ? loggedUser?.firstName
                                        : 'Não informado'}
                                </p>
                            </Col>
                            <Col md={6} sm={12} className="mb-3">
                                <h5 className="mb-0">Último nome</h5>
                                <p className="mb-4">
                                    {loggedUser?.lastName ? loggedUser?.lastName : 'Não informado'}
                                </p>
                            </Col>
                            <Col md={6} sm={12} className="mb-3">
                                <h5 className="mb-0">CPF</h5>
                                <p className="mb-4">
                                    {loggedUser?.cpf ? loggedUser?.cpf : 'Não informado'}
                                </p>
                            </Col>
                            <Col md={6} sm={12} className="mb-3">
                                <h5 className="mb-0">Número de Celular</h5>
                                <p className="mb-4">
                                    {loggedUser?.phone ? loggedUser?.phone : 'Não informado'}
                                </p>
                            </Col>
                            <Col md={6} sm={12} className="mb-3">
                                <h5 className="mb-0">Data de Nascimento</h5>
                                <p className="mb-4">
                                    {loggedUser?.birthday ? loggedUser?.birthday : 'Não informado'}
                                </p>
                            </Col>
                            <Col md={6} sm={12} className="mb-3">
                                <h5 className="mb-0">Cidade</h5>
                                <p className="mb-4">
                                    {loggedUser?.city ? loggedUser?.city : 'Não informado'}
                                </p>
                            </Col>
                            <Col md={6} sm={12} className="mb-3">
                                <h5 className="mb-0">Estado</h5>
                                <p className="mb-4">
                                    {loggedUser?.state ? loggedUser?.state : 'Não informado'}
                                </p>
                            </Col>
                            <Col md={6} sm={12} className="mb-3">
                                <h5 className="mb-0">CEP</h5>
                                <p className="mb-4">
                                    {loggedUser?.cep ? loggedUser?.cep : 'Não informado'}
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
                <Card.Body className="border-top">
                    <div>
                        <h4 className="mb-0">Editar informações pessoais</h4>
                        <p className="mb-4">Edite suas informações pessoais e endereço.</p>

                        {/* Form */}
                        <Form>
                            <Row>
                                {/* First name */}
                                <Col md={6} sm={12} className="mb-3">
                                    <Form.Group className="mb-3" controlId="formFirstName">
                                        <Form.Label>Primeiro Nome</Form.Label>
                                        <Form.Control type="text" {...register('firstName')} />
                                    </Form.Group>
                                </Col>

                                {/* Last name */}
                                <Col md={6} sm={12} className="mb-3">
                                    <Form.Group className="mb-3" controlId="formLastName">
                                        <Form.Label>Último Nome</Form.Label>
                                        <Form.Control type="text" {...register('lastName')} />
                                    </Form.Group>
                                </Col>

                                {/* CPF */}
                                <Col md={6} sm={12} className="mb-3">
                                    <Form.Group className="mb-3" controlId="CPF">
                                        <Form.Label>CPF</Form.Label>
                                        <ReactInputMask
                                            className="form-control"
                                            mask="999.999.999-99"
                                            value={cpf}
                                            onChange={(event) => setCpf(event.target.value)}
                                        />
                                    </Form.Group>
                                </Col>

                                {/* Phone */}
                                <Col md={6} sm={12} className="mb-3">
                                    <Form.Group className="mb-3" controlId="formPhone">
                                        <Form.Label>Número de Celular</Form.Label>
                                        <ReactInputMask
                                            className="form-control"
                                            type="text"
                                            mask="(99) 99999-9999"
                                            {...register('phone', {
                                                minLength: 11,
                                            })}
                                        />
                                        {errors.phone && errors.phone.type === 'minLength' && (
                                            <p className="errorMessageInput">Telefone inválido*</p>
                                        )}
                                    </Form.Group>
                                </Col>

                                {/* BirthDate */}

                                {/* Address */}
                                <Col md={6} sm={12} className="mb-3">
                                    {/* Username or email */}
                                    <Form.Label>CEP</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="cep"
                                        placeholder="CEP"
                                        {...register('cep', {
                                            required: true,
                                        })}
                                    />
                                    {errors?.cep?.type === 'required' && (
                                        <p className="errorMessageInput">Campo obrigatório*</p>
                                    )}
                                </Col>

                                {/* Address number*/}

                                {/* State */}

                                {/* Button */}
                                <Col
                                    sm={12}
                                    md={12}
                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                >
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        onClick={handleSubmit(onSubmit)}
                                        disabled={loading}
                                    >
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm me-2"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        )}
                                        Atualizar perfil
                                    </Button>

                                    <Button
                                        variant="primary"
                                        type="button"
                                        onClick={onLinkAccount}
                                        disabled={loading}
                                    >
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm me-2"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        )}
                                        Vincular conta
                                    </Button>
                                </Col>
                                <Col md={12} sm={12} className="mb-3" style={{ marginTop: '10px' }}>
                                    {successMessage ? (
                                        <Alert variant="success">{successMessage}</Alert>
                                    ) : (
                                        errorMessage !== undefined && (
                                            <Alert variant="danger">{errorMessage}</Alert>
                                        )
                                    )}
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Card.Body>
            </Card>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar imagem</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex align-items-center justify-content-center modal-image">
                    <div>
                        {' '}
                        <span>Ajuste sua imagem para que fique centralizada no círculo</span>
                    </div>
                    <div>
                        <AvatarEditor
                            ref={editorRef}
                            image={image}
                            width={270}
                            height={270}
                            border={50}
                            color={[255, 255, 255, 0.6]} // RGBA
                            scale={1}
                            rotate={0}
                            borderRadius={360}
                            className="avatar-editor"
                            showGrid
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleSave} disabled={loading}>
                        {loading && (
                            <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        )}
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
        </ProfileLayout>
    );
};

export default EditProfile;
