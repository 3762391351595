// import node module libraries
import { useLocation, Link } from 'react-router-dom';
import React from 'react';
import {
	Card,
	Form,
	Row,
	Col,
	Button,
	FormControl,
	Alert,
	Modal,
	Table,
	Dropdown,
	CloseButton
} from 'react-bootstrap';

import { Fragment, useEffect, useState } from 'react';

import occupationAreas from 'components/serviceProviders/occupationAreas';
import CurrencyInput from 'react-currency-input-field';
import { useNavigate } from 'react-router-dom';

// import custom components
import { FormSelect } from 'components/elements/form-select/FormSelect';

// import profile layout wrapper
import ProfileLayout from 'components/marketing/student/ProfileLayout';

import './styles/styles.css';
import '../../../components/Style.css';

import axios from 'axios';

import { useForm, Controller } from 'react-hook-form';

import { TagsInput } from 'react-tag-input-component';

import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

// Import imagens / icons
import exclamationFieldDrag from '../../../assets/images/icons/ponto-de-exclamacao.png';
import IconFieldDrag from '../../../assets/images/icons/icon-field-drag.png';
import IconMessageSuccess from '../../../assets/images/icons/verificado.png';
import IconMessageError from '../../../assets/images/icons/cancelar.png';

const EditPortfolio = () => {
	const pathInfo = useLocation();
	const account = pathInfo.pathname.substring(21, 11);
	const [userId, setUserId] = useState([]);
	const [loading, setLoading] = useState(false);
	const [skills, setSkills] = useState([]);
	const [successMessage, setSuccessMessage] = useState();
	const [errorMessage, setErrorMessage] = useState();
	const [loggedUser, setLoggedUser] = useState();
	const [userOfImages, setUserOfImages] = useState([]);

	// Start open modal drag and drop
	const [openModalDrag, setOpenModalDrag] = useState(false);

	const handleCloseModalDrag = () => {
		setOpenModalDrag(false);
		setMessaSaveDrag('');
		setMessaSaveErrorDrag('');
	};
	const handleShowModalDrag = () => setOpenModalDrag(true);
	// End open modal drag and drop

	const options = occupationAreas.map((area) => ({
		label: area.area,
		value: area.area
	}));

	options.sort((a, b) => a.value.localeCompare(b.value));

	const {
		register,
		handleSubmit,
		control,
		formState: { errors }
	} = useForm();

	const {
		register: registerForm2,
		handleSubmit: handleSubmitForm2,
		formState: { errors: errorsForm2 },
		reset: resetForm2
	} = useForm();

	const {
		register: registerForm3,
		handleSubmit: handleSubmitForm3,
		formState: { errors: errorsForm3 },
		reset: resetForm3
	} = useForm();

	const [services, setServices] = useState([]);
	const [imageLoaded, setImageLoaded] = useState();
	const url = process.env.REACT_APP_API_URL;
	const hash = localStorage.getItem('hash');
	const [imageMessage, setImageMessage] = useState();
	const [imageError, setImageError] = useState(false);
	const [addServiceSuccess, setAddServiceSuccess] = useState('');

	const getLoggedUserData = async () => {
		setLoading(true);
		const config = {
			headers: {
				Authorization: `Bearer ${hash}`
			}
		};

		axios
			.get(`${url}accounts/authentication/logged-user`, config)
			.then((response) => {
				setLoading(false);
				setUserId(response.data._id);
				setLoggedUser(response.data);
				setUserOfImages(response.data.portfolioPictures);
				setServices(response?.data?.services);
				setSkills(response?.data?.skills);
			})
			.catch((error) => {
				console.error('Erro ao buscar dados da API:', error);
				setLoading(false);
			});
	};

	function submitPortfolioData(data) {
		setLoading(true);
		setSuccessMessage();
		setErrorMessage();

		const userType = data.wannaBeProvider === true ? 'provider' : 'user';

		axios({
			method: 'patch',
			url: `${url}accounts/users/${userId}`,
			headers: {
				Authorization: `Bearer ${hash}`
			},
			data: {
				portfolioTitle:
					data.portfolioTitle === '' ? loggedUser.portfolioTitle : data.portfolioTitle,
				portfolioAbout:
					data.portfolioAbout === '' ? loggedUser.portfolioAbout : data.portfolioAbout,
				services: services,
				skills: skills,
				occupationArea:
					data.occupationArea === '' ? loggedUser.occupationArea : data.occupationArea,
				userType: userType
			}
		})
			.then((response) => {
				setLoading(false);
				setSuccessMessage('Portfólio atualizado com Sucesso!');
				getLoggedUserData();
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
				setErrorMessage('Erro ao atualizar portfólio. Tente novamente mais tarde.');
			});
	}

	async function setPortfolioImages(e) {
		setImageMessage();
		setImageLoaded();
		setLoading(true);
		const { files } = e.target;
		const newFormData = new FormData();

		let podeEnviar = true;

		if (e.target.files.length > 12 || loggedUser?.portfolioPictures.length >= 12) {
			setImageMessage('Você pode enviar até 12 fotos no portfólio');
			setImageError(true);
			podeEnviar = false;
			setLoading(false);
			e.target.value = null;
		}

		for (let i = 0; i < e.target.files.length && e.target.files.length <= 12; i++) {
			newFormData.append('files', files[i]);
		}

		const config = {
			headers: {
				Authorization: `Bearer ${hash}`
			}
		};

		if (podeEnviar === true) {
			await axios
				.post(`${url}accounts/users/add-portfolio-pictures`, files, config)

				.then((response) => {
					setImageLoaded(true);

					setLoading(false);
					if (e.target.files.length <= 1) {
						setImageMessage('Imagem enviada com sucesso!');

						e.target.value = null;
					} else {
						setImageMessage('Imagens enviadas com sucesso!');

						e.target.value = null;
					}

					getLoggedUserData();
					setLoading(false);
				})

				.catch((error) => {
					setImageLoaded(false);
					setLoading(false);
					setImageMessage('Erro ao enviar imagem, tente novamente mais tarde!');
					e.target.value = null;
				});
		}
	}

	const addServiceList = async (data) => {
		setLoading(true);
		const newData = [
			{
				title: data.serviceTitle,
				description: data.serviceDescription,
				value: parseFloat(data.value.replace(/\./g, ''))
			}
		];

		const config = {
			headers: {
				Authorization: `Bearer ${hash}`
			}
		};

		await axios
			.post(`${url}accounts/users/add-services`, newData, config)

			.then((response) => {
				setLoading(false);
				resetForm2({
					serviceTitle: '',
					serviceDescription: '',
					value: ''
				});
				setAddServiceSuccess(true);
				getLoggedUserData();
			})

			.catch((error) => {
				setLoading(false);
				setAddServiceSuccess(true);
			});
	};

	const [removingService, setRemovingService] = useState(false);
	const [removingServiceId, setRemovingServiceId] = useState();
	const removerService = async (serviceId) => {
		setRemovingService(true);
		setRemovingServiceId(serviceId.taskId);
		const config = {
			headers: {
				Authorization: `Bearer ${hash}`
			}
		};

		await axios
			.delete(`${url}accounts/users/remove-service/${serviceId.taskId.id}`, config)

			.then((response) => {
				setLoading(false);
				setRemovingService(false);
				getLoggedUserData();
			})

			.catch((error) => {
				setLoading(false);
				setRemovingService(false);
			});
	};

	const [editSuccess, setEditSuccess] = useState();
	const [editError, setEditError] = useState();

	const editService = async (data) => {
		const config = {
			headers: {
				Authorization: `Bearer ${hash}`
			}
		};

		const newData = {
			title: data.serviceTitle,
			description: data.serviceDescription,
			value: parseFloat(data.value.replace(/\./g, ''))
		};

		await axios
			.put(`${url}accounts/users/update-service/${editServiceId}`, newData, config)

			.then((response) => {
				setLoading(false);
				getLoggedUserData();
				setEditSuccess(true);
			})

			.catch((error) => {
				setLoading(false);
				setEditError(false);
			});
	};

	useEffect(() => {
		getLoggedUserData();
	}, []);

	const [show, setShow] = useState();

	const handleRemoveImage = async (id, e) => {
		setLoading(true);
		e.preventDefault();
		await axios({
			method: 'delete',
			url: `${url}accounts/users/remove-portfolio-picture/${id}`,
			headers: {
				Authorization: `Bearer ${hash}`
			}
		})
			.then(function (response) {
				getLoggedUserData();
				setLoading(false);
				setImageMessage('Imagem removida com sucesso!');
			})
			.catch((error) => {
				setLoading(false);
				setImageMessage('Erro ao remover imagem, tente novamente mais tarde!');
			});
	};

	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to=""
			className="btn-icon btn btn-ghost btn-sm rounded-circle"
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			{children}
		</Link>
	));

	const ActionMenu = (taskId) => {
		return (
			<Dropdown>
				<Dropdown.Toggle as={CustomToggle}>
					<i className="fe fe-more-horizontal fs-4 text-primary"></i>
				</Dropdown.Toggle>
				<Dropdown.Menu align={'end'}>
					<Dropdown.Item
						eventKey="1"
						className="d-flex align-items-center"
						onClick={() => handleShowEditModal(taskId)}
					>
						<i className="dropdown-item-icon fe fe-edit-2"></i> Editar
					</Dropdown.Item>

					<Dropdown.Item
						eventKey="7"
						className="d-flex align-items-center"
						onClick={() => removerService(taskId)}
					>
						<i className="dropdown-item-icon fe fe-trash-2"></i>
						Remover
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [showEditModal, setShowEditModal] = useState(false);
	const [editServiceTitle, setEditServiceTitle] = useState();
	const [editServiceDescription, setEditServiceDescription] = useState();
	const [editServiceValue, setEditServiceValue] = useState();
	const [editServiceId, setEditServiceId] = useState();

	const handleShowEditModal = (service) => {
		setShowEditModal(true);
		setEditServiceTitle(service.taskId.title);
		setEditServiceDescription(service.taskId.description);
		setEditServiceValue(service.taskId.value);
		setEditServiceId(service.taskId.id);
	};

	const handleCloseEditModal = () => setShowEditModal(false);

	// Start drag and drop
	const [messageSaveDrag, setMessaSaveDrag] = useState('');
	const [messageSaveErrorDrag, setMessaSaveErrorDrag] = useState('');

	const reorder = (list, startindex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startindex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		const itemsListImages = reorder(userOfImages, result.source.index, result.destination.index);
		setUserOfImages(itemsListImages);
	};

	const changeUserImage = () => {
		const data = userOfImages.map((image, index) => ({
			_id: image._id,
			position: index
		}));

		axios({
			method: 'PATCH',
			url: `${url}accounts/users/change-portfolio-order`,
			headers: {
				Authorization: `Bearer ${hash}`
			},
			data
		})
			.then((response) => {
				setMessaSaveDrag('Ordem das imagens alterada!');
				setTimeout(() => {
					handleCloseModalDrag(false);
				}, 1000);
			})
			.catch((error) => {
				setMessaSaveErrorDrag('Erro ao alterar a ordem das imagens!');
				setTimeout(() => {
					handleCloseModalDrag(false);
				}, 1000);
			});
	};
	// End drag and drop

	return (
		<ProfileLayout>
			{account === 'instructor'}
			<Card className="border-0 edit-porfile-card">
				<Card.Header>
					<div className="mb-3 mb-lg-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h3 className="mb-0">Portfólio</h3>
						</div>

						<p className="mb-0">
							Nesta página você pode editar as informações que vão aparecer no seu Portfólio.
							Lembre-se de preencher todas as informações, isso ajuda a deixar seu perfil mais
							bonito e atrativo 😁.
						</p>
					</div>
				</Card.Header>
				<Card.Body>
					<div className="d-lg-flex align-items-center justify-content-between"></div>

					<Form>
						<Row>
							<div className="d-lg-flex align-items-end justify-content-between mb-5">
								<div className="d-flex align-items-center mb-4 mb-lg-0">
									<div className="ms-0">
										<h4 className="mb-3">Fotos do Portfolio</h4>
										<p className="mb-0">Formato: PNG ou JPG</p>
										<small>É permitido enviar até 12 fotos no portfólio</small>
									</div>
								</div>

								<div className="edit_image_profile">
									<Form.Control
										id="company-logo"
										type="file"
										placeholder="Enviar imagem"
										onChange={(e) => setPortfolioImages(e)}
										multiple={true}
									/>
									{loading && (
										<span
											className="spinner-border spinner-border-sm me-2"
											role="status"
											aria-hidden="true"
										></span>
									)}
									{imageLoaded === true ? (
										<p variant="success text-success">{imageMessage}</p>
									) : (
										imageError === true && (
											<p variant="text-danger danger" style={{ color: 'red' }}>
												{imageMessage}
											</p>
										)
									)}
								</div>
							</div>
							{/* Start drag and drop */}
							{loggedUser?.portfolioPictures && (
								<>
									{userOfImages && (
										<div className="button-image-rearrange-wrapper">
											<Button onClick={handleShowModalDrag}>Organizar imagens</Button>
										</div>
									)}

									<div className="image-grid-wrapper">
										{userOfImages?.map((item, index) => (
											<div key={index} className="image-wrapper">
												<img src={item.url} alt="img" />
												<CloseButton
													className="btn-close remove-button"
													onClick={(e) => handleRemoveImage(item._id, e)}
												/>
											</div>
										))}
									</div>
									{/* <hr className="my-5" /> */}
								</>
							)}
						</Row>
					</Form>
				</Card.Body>
				<Card.Body className="border-top">
					<Form>
						<Row>
							<Modal show={openModalDrag} onHide={handleCloseModalDrag}>
								<Modal.Header closeButton>
									<Modal.Title>Organize a ordem de exibição das imagens</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<div className="image-grid-container">
										<div className="message-droppable-wrapper">
											<img className="droppable-icon" alt="img" src={exclamationFieldDrag} />
											<span className="message-droppable">Clique e arraste para organizar</span>
										</div>
										<DragDropContext onDragEnd={onDragEnd}>
											<Droppable droppableId="images" type="list" direction="vertical">
												{(provided) => (
													<div
														className="droppable-container"
														ref={provided.innerRef}
														{...provided.droppableProps}
													>
														{userOfImages?.map((item, index) => (
															<Draggable draggableId={String(index)} index={index} key={index}>
																{(provider) => (
																	<div
																		key={index}
																		className="image-container"
																		{...provider.draggableProps}
																		{...provider.dragHandleProps}
																		ref={provider.innerRef}
																	>
																		<img src={item.url} alt="img" />
																		<img
																			className="icon-field-drag"
																			src={IconFieldDrag}
																			alt="icon"
																		/>
																	</div>
																)}
															</Draggable>
														))}
														{provided.placeholder}
													</div>
												)}
											</Droppable>
										</DragDropContext>
									</div>
								</Modal.Body>
								<Modal.Footer>
									<div className="modal-footer-wrapper">
										<Button variant="primary" onClick={changeUserImage}>
											Salvar
										</Button>

										{messageSaveDrag !== '' && (
											<div className="message-success-wrapper">
												<img
													className="message-success-icon"
													alt="success-icon"
													src={IconMessageSuccess}
												/>
												<span className="message-success-images">{messageSaveDrag}</span>
											</div>
										)}

										{messageSaveErrorDrag !== '' && (
											<div className="message-error-wrapper">
												<img
													className="message-error-icon"
													alt="error-icon"
													src={IconMessageError}
												/>
												<span className="message-error-images">{messageSaveErrorDrag}</span>
											</div>
										)}
									</div>
								</Modal.Footer>
							</Modal>
							{/* End drag and drop */}
							{loggedUser?.userType !== 'provider' ? (
								<Col md={10} sm={12} className="mb-3">
									<Form.Group className="mb-0" controlId="formProfileTitle">
										<Form.Label>Quero prestar Serviço</Form.Label>

										<Controller
											name="wannaBeProvider"
											control={control}
											defaultValue=""
											render={({ field }) => (
												<Form.Check
													type="checkbox"
													label="Se deseja disponibilizar seus serviços na Prestadio, por favor, marque esta caixa."
													{...field}
												/>
											)}
										/>
									</Form.Group>
								</Col>
							) : (
								''
							)}

							<Col md={10} sm={12} className="mb-3">
								<Form.Group className="mb-0" controlId="formProfileTitle">
									<Form.Label>Título do Perfil</Form.Label>

									<Form.Control
										type="text"
										placeholder={loggedUser?.portfolioTitle}
										{...register('portfolioTitle')}
										maxLength={40}
									/>
									<div>
										<span>Será exibido abaixo do seu nome na página de Prestadores.</span>
									</div>
								</Form.Group>
							</Col>

							<Col lg={8} md={8} sm={12} className="mt-3 mb-3">
								<Form.Group className="mb-3" controlId="formProfileTitle">
									<Form.Label>Área de atuação</Form.Label>
									<Controller
										name="occupationArea"
										control={control}
										defaultValue=""
										render={({ field }) => (
											<FormSelect
												{...field}
												options={options}
												placeholder="Selecione a sua área de atuação"
												className="mb-2"
											/>
										)}
									/>
									{loggedUser?.occupationArea ? (
										<div>
											<span>Atual: {loggedUser?.occupationArea}</span>
										</div>
									) : (
										''
									)}
								</Form.Group>
							</Col>

							<Col md={12} sm={12} className="mb-3">
								<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
									<Form.Label>Sobre você</Form.Label>
									<Form.Control as="textarea" rows={10} {...register('portfolioAbout')} />
								</Form.Group>
								{/* <span style={{ marginLeft: '10px' }}>{loggedUser?.portfolioAbout}</span> */}
							</Col>
							<Col md={12} sm={6} className="mb-2 mt-3">
								<Form.Label>Serviços Ofertados</Form.Label>
								<div className="mb-2">
									<span>Clique em "Adicionar Serviço" para adicionar um novo serviço.</span>
								</div>

								<div>
									<Button
										variant="primary"
										id="button-addon2"
										onClick={handleShow}
										className="mb-2"
									>
										Adicionar Serviços
									</Button>
								</div>
							</Col>
						</Row>
					</Form>
				</Card.Body>
				{services && services.length > 0 ? (
					<Col className="col-service-wrapper">
						<Table responsive className="text-nowrap">
							<thead>
								<tr>
									<th scope="col" className="text-start">
										Serviço
									</th>
									<th scope="col" className="text-start">
										Descrição
									</th>

									<th scope="col" className="text-start">
										Valor
									</th>
									<th scope="col" className="text-start">
										Opções
									</th>
								</tr>
							</thead>
							<tbody>
								{services.map((service) => (
									<tr key={service.id}>
										<th>{service.title}</th>
										<th
											style={{
												maxWidth: '300px',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
												whiteSpace: 'nowrap'
											}}
										>
											{service.description}
										</th>
										<th>R$ {service.value}</th>
										<th className="text-start">
											{removingService === true && removingServiceId === service.id ? (
												<span
													className="spinner-border spinner-border-sm me-2"
													role="status"
													aria-hidden="true"
												></span>
											) : (
												<ActionMenu taskId={service}></ActionMenu>
											)}
										</th>
									</tr>
								))}
							</tbody>
						</Table>
					</Col>
				) : (
					<p className="ps-4">Nenhum serviço adicionado ainda.</p>
				)}
				<Card.Body>
					<Form>
						<Row>
							<Col md={12} sm={12} className="mb-3">
								<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
									<Form.Label>Competências</Form.Label>
									<pre>
										{skills &&
											skills?.map((item) => {
												return (
													<span key={item} className="badge text-bg-primary skills-badge">
														{item}
													</span>
												);
											})}
									</pre>
									<TagsInput
										value={skills || []}
										onChange={setSkills}
										name="occupationArea"
										//placeHolder="Competências"
									/>
									<p className="skills-subtitle">
										Digite uma Competência e aperte Enter para inserir uma nova
									</p>
								</Form.Group>
							</Col>
						</Row>
					</Form>
					<Col md={12} sm={12} className="mb-3">
						<Button
							variant="primary"
							type="submit"
							onClick={handleSubmit(submitPortfolioData)}
							disabled={loading}
						>
							{loading && (
								<span
									className="spinner-border spinner-border-sm me-2"
									role="status"
									aria-hidden="true"
								></span>
							)}
							Atualizar portfólio
						</Button>
					</Col>

					<Col md={12} sm={12} className="mb-3">
						{successMessage ? (
							<Alert variant="success">{successMessage}</Alert>
						) : (
							errorMessage !== undefined && <Alert variant="danger">{errorMessage}</Alert>
						)}
					</Col>
				</Card.Body>
			</Card>

			<Fragment>
				<Modal show={show} onHide={handleClose} size="lg">
					<Modal.Header closeButton>
						<Modal.Title>Adicionar Serviço</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="mb-2">
							<span className="mb-4">
								Preencha as informações do serviço que você deseja cadastrar. Lembre-se de informar
								com clareza os detalhes 😁
							</span>
						</div>

						<FormControl
							placeholder="Serviço"
							aria-describedby="basic-addon2"
							type="text"
							className="ml-2 mb-2"
							{...registerForm2('serviceTitle', {
								required: 'Informe o Título do Serviço'
							})}
						/>
						{errorsForm2.serviceTitle && (
							<h5 className="text-danger">{errorsForm2.serviceTitle.message}</h5>
						)}

						<FormControl
							placeholder="Descrição - No máximo 300 caracteres"
							aria-describedby="basic-addon2"
							type="text-area"
							as="textarea"
							maxLength={300}
							className="ml-2 mb-2"
							{...registerForm2('serviceDescription', {
								required: 'Informe a Descrição do Serviço'
							})}
						/>
						{errorsForm2.serviceDescription && (
							<h5 className="text-danger">{errorsForm2.serviceDescription.message}</h5>
						)}

						<CurrencyInput
							className="form-control mb-2"
							placeholder="Valor"
							intlConfig={{
								locale: 'pt-BR'
							}}
							{...registerForm2('value', {
								required: 'Informe o valor do Serviço'
							})}
						/>
						{errorsForm2.value && <h5 className="text-danger">{errorsForm2.value.message}</h5>}
						<Button className="mb-2" variant="primary" onClick={handleSubmitForm2(addServiceList)}>
							Salvar e Adicionar Novo Serviço
						</Button>
						{addServiceSuccess === true && <h5 className="text-success">Serviço cadastrado!</h5>}

						{addServiceSuccess === false && (
							<h5 className="text-danger">Falha ao cadastrar serviço!</h5>
						)}
					</Modal.Body>
					{/* Modal footer add services */}
					{/* <Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Fechar
						</Button>
						<Button variant="primary" onClick={handleSubmitForm2(addServiceList)}>
							Salvar
						</Button>
					</Modal.Footer> */}
				</Modal>
			</Fragment>
			<Fragment>
				<Modal show={showEditModal}>
					<Modal.Header>
						<Modal.Title>Editar Serviço</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="mb-2">
							<span className="mb-4">
								Edite as informações do serviço. Lembre-se de informar com clareza os detalhes 😁
							</span>
						</div>
						<Form.Label>Serviço</Form.Label>
						<FormControl
							placeholder="Serviço"
							aria-describedby="basic-addon2"
							type="text"
							className="ml-2 mb-2"
							value={editServiceTitle}
							onClick={(e) => setEditServiceTitle()}
							{...registerForm3('serviceTitle', {
								required: 'Informe o Título do Serviço'
							})}
						/>
						{errorsForm3.serviceTitle && (
							<h5 className="text-danger">{errorsForm3.serviceTitle.message}</h5>
						)}
						<Form.Label>Descrição</Form.Label>
						<small> - No máximo 300 caracteres</small>
						<FormControl
							placeholder="Descrição"
							aria-describedby="basic-addon2"
							type="text-area"
							as="textarea"
							maxLength={300}
							rows={5}
							value={editServiceDescription}
							onClick={(e) => setEditServiceDescription()}
							className="ml-2 mb-2"
							{...registerForm3('serviceDescription', {
								required: 'Informe a Descrição do Serviço'
							})}
						/>

						{errorsForm3.serviceDescription && (
							<h5 className="text-danger">{errorsForm3.serviceDescription.message}</h5>
						)}
						<Form.Label>Valor</Form.Label>
						<CurrencyInput
							className="form-control mb-2"
							placeholder="Valor"
							value={editServiceValue}
							onClick={(e) => setEditServiceValue()}
							intlConfig={{
								locale: 'pt-BR'
							}}
							{...registerForm3('value', {
								required: 'Informe o valor do Serviço'
							})}
						/>
						{errorsForm3.value && <h5 className="text-danger">{errorsForm3.value.message}</h5>}
						{editSuccess === true && <h5 className="text-success">Serviço editado com sucesso!</h5>}

						{editError === false && <h5 className="text-danger">Falha ao editar serviço!</h5>}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseEditModal}>
							Fechar
						</Button>
						<Button variant="primary" onClick={handleSubmitForm3(editService)}>
							Salvar
						</Button>
					</Modal.Footer>
				</Modal>
			</Fragment>
		</ProfileLayout>
	);
};

export default EditPortfolio;
